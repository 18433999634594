import { useEffect, useState } from 'react'

import { Select, Table } from 'antd'
import { ColumnType } from 'antd/es/table'

import { TochigiLayout } from '../layout/TochigiLayout'

import { useFetchReportsSchoolPerformance } from '../../providers/TochigiProvider'

const { Option } = Select

const tableColumns: ColumnType<any>[] = [
  {
    title: '市区町村',
    dataIndex: ['school', 'city_name'],
    key: 'city_name',
  },
  {
    title: '学校番号',
    dataIndex: 'prefecture_school_code',
    key: 'prefecture_school_code',
    sorter: (a, b) => a.prefecture_school_code! - b.prefecture_school_code!,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: '学校名',
    dataIndex: 'school_name',
    key: 'school_name',
  },
  {
    title: '昨年度との(A+B)-(D+E)の差',
    key: 'last_year_performance_comparison',
    sorter: (a, b) => {
      let a_this_year_value = a.performance
      let a_last_year_value = a.previous_result?.performance
      let b_this_year_value = b.performance
      let b_last_year_value = b.previous_result?.performance
      if (!b_last_year_value) {
        return 0
      } else if (!a_last_year_value) {
        return 0
      }
      return (
        a_this_year_value -
        a_last_year_value -
        (b_this_year_value - b_last_year_value)
      )
    },
    sortDirections: ['descend'],
    render: (value) => {
      let this_year_value = value.performance
      let last_year_value = value.previous_result?.performance
      if (!last_year_value) {
        return null
      }
      return (this_year_value - last_year_value).toFixed(1)
    },
  },
  {
    title: '昨年度の(A+B)-(D+E)',
    dataIndex: ['previous_result', 'performance'],
    key: 'last_year_performance',
    sorter: (a, b) =>
      a.previous_result?.performance - b.previous_result?.performance,
    sortDirections: ['descend'],
    render: (value) => value?.toFixed(1),
  },
  {
    title: '(A+B)-(D+E)',
    dataIndex: 'performance',
    key: 'performance',
    sorter: (a, b) => a.performance! - b.performance!,
    sortDirections: ['descend'],
    render: (value) => value?.toFixed(1),
  },
  {
    title: '実施率',
    dataIndex: 'participation',
    key: 'participation',
    sorter: (a, b) => a.participation! - b.participation!,
    sortDirections: ['ascend', 'descend'],
    render: (value) => value?.toFixed(1),
  },
  {
    title: '受験者',
    dataIndex: 'total_count',
    key: 'total_count',
    sorter: (a, b) => a.total_count! - b.total_count!,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: '全校生徒',
    dataIndex: 'total_student_count',
    key: 'total_student_count',
    sorter: (a, b) => a.total_student_count! - b.total_student_count!,
    sortDirections: ['ascend', 'descend'],
  },
]

const ReportSchoolPerformanceTable = ({
  year,
  schoolScale,
}: {
  year: number
  schoolScale: number
}) => {
  const [schools, setSchools] = useState<any[]>([])

  const { data, loading } = useFetchReportsSchoolPerformance()

  useEffect(() => {
    if (!data) {
      return
    }

    let _schools: any[] = []
    _schools = data ?? []
    if (schoolScale !== 0) {
      _schools = _schools.filter((s) => {
        switch (schoolScale) {
          case 1:
            return s.total_student_count <= 35
          case 2:
            return s.total_student_count > 36 && s.total_student_count <= 105
          case 3:
            return s.total_student_count > 106 && s.total_student_count <= 315
          case 4:
            return s.total_student_count > 316 && s.total_student_count <= 525
          case 5:
            return s.total_student_count > 526
          default:
            return true
        }
      })
    }
    setSchools(_schools)
  }, [data, year, schoolScale])

  if (loading) {
    return null
  }

  if (!data) {
    return null
  }

  return (
    <div>
      <Table
        className="w-full overflow-x-scroll"
        columns={tableColumns}
        dataSource={schools}
        rowKey="prefectureSchoolCode"
      />
    </div>
  )
}

export function ReportSchoolPerformanceProgressListPage() {
  const [year, setYear] = useState(2021)
  const [schoolScale, setSchoolScale] = useState(1)

  return (
    <TochigiLayout>
      <div className="space-x-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2021}>2021 ({makeYearName(2021)})</Option>
        </Select>
        <Select value={schoolScale} onChange={(v) => setSchoolScale(v)}>
          <Option value={0}>全て</Option>
          <Option value={1}>小規模校（生徒数 35 人以下）</Option>
          <Option value={2}>小規模校（生徒数 36 人～105 人）</Option>
          <Option value={3}>中規模校（生徒数 106 人～315 人）</Option>
          <Option value={4}>中規模校（生徒数 316 人～525 人）</Option>
          <Option value={5}>大規模校（生徒数 526 人以上） </Option>
        </Select>
      </div>

      <ReportSchoolPerformanceTable year={year} schoolScale={schoolScale} />
    </TochigiLayout>
  )
}

const makeYearName = (y: number): string => {
  if (y >= 2019) {
    return `R${y - 2018}`
  }
  return `H${y - 1988}`
}
