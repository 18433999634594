import { ReportScoreByScoreType } from '../pages/ReportCrossAnalysisGraphPage'

import { Card } from 'antd'

import Title from 'antd/es/typography/Title'
import {
  LineChart,
  CartesianGrid,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Symbols,
} from 'recharts'
import { SymbolType } from 'recharts/types/util/types'

export function makeUnitName(scoreType: string): string {
  switch (scoreType) {
    case 'grip':
      return '(kg)'
    case 'sitUps':
      return '(回)'
    case 'bending':
      return '(cm)'
    case 'sideJump':
      return '(点)'
    case 'shuttleRun':
      return '(回)'
    case 'sprintRun':
      return '(秒)'
    case 'standingJump':
      return '(cm)'
    case 'handballThrow':
      return '(m)'
    case 'totalScore':
      return '(点)'
  }
  return ''
}

export function makeGradeName(c: any, grade: number): string {
  switch (c) {
    case 'ELEMENTARY_SCHOOL':
      return `小${grade}`
    case 'JUNIOR_HIGH_SCHOOL':
      return `中${grade}`
    case 'HIGH_SCHOOL':
      return `高${grade}`
  }
  return ''
}

const scoreTypes = {
  height: '身長',
  weight: '体重',
  grip: '握力',
  sitUps: '上体起こし',
  bending: '長座体前屈',
  sideJump: '反復横跳び',
  shuttleRun: 'シャトルラン',
  sprintRun: '50m走',
  standingJump: '立ち幅跳び',
  handballThrow: 'ボール投げ',
  totalScore: '得点合計',
}

type Props = {
  data: ReportScoreByScoreType
  visibleCategories: string[]
}

const colors = ['#aa0000', '#aaaa00', '#008000', '#0000aa']

const legendTypes: SymbolType[] = ['square', 'cross', 'diamond', 'triangle']

const CrossAnalysisLineChart = ({ data, visibleCategories }: Props) => {
  const { scoreType } = data
  const scoreTypeName = scoreTypes[scoreType]
  return (
    <Card title={scoreTypeName} style={{ pageBreakAfter: 'always' }}>
      {data.genders.map((d, index) => {
        const genderName = (d.gender as any) === 'MALE' ? '男子' : '女子'
        const dataKeys = new Set<string>()

        let chartData = d.grades.map((grade) => {
          if (visibleCategories.indexOf(grade.schoolCategory) === -1) {
            return null
          }
          let schoolCategory: string = grade.schoolCategory as any
          schoolCategory =
            schoolCategory === 'ALL_DAY_HIGH_SCHOOL'
              ? 'HIGH_SCHOOL'
              : schoolCategory
          const gradeName = makeGradeName(schoolCategory as any, grade.grade)
          const cd = {
            name: gradeName,
          }
          grade.answers.forEach((answer) => {
            dataKeys.add(answer.answer)
            cd[answer.answer] = answer.score
          })
          return cd
        })
        chartData = chartData.filter((v) => v)
        const keys = Array.from(dataKeys)

        const lineCharProps = {
          data: chartData,
          width: 0,
          height: 0,
        }
        const legendWrapperStyle = {}

        const unitName = {
          height: '(cm)',
          weight: '(kg)',
        }

        const domain = {
          grip: {
            min: 0,
            max: 50,
          },
          handballThrow: {
            min: 0,
            max: 35,
          },
        }

        const lineChart = (
          <LineChart {...lineCharProps}>
            <Legend wrapperStyle={legendWrapperStyle} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" type="category" />
            <YAxis
              tickCount={20}
              domain={[
                (dataMin) => domain[scoreType]?.min ?? Math.round(dataMin - 3),
                (dataMax) => domain[scoreType]?.max ?? Math.round(dataMax + 3),
              ]}
              reversed={scoreType === 'sprintRun'}
              label={{
                value: unitName[scoreType] ?? makeUnitName(scoreType),
                angle: -90,
                position: 'insideLeft',
              }}
            />
            <Tooltip />
            {keys.map((key, idx) => (
              <Line
                key={key}
                dataKey={key}
                stroke={colors[idx]}
                isAnimationActive={false}
                legendType={legendTypes[idx % legendTypes.length]}
                dot={
                  <Symbols
                    type={legendTypes[idx % legendTypes.length]}
                    fillOpacity={idx >= legendTypes.length ? 1 : 0.2}
                    fill={colors[idx]}
                  />
                }
              />
            ))}
          </LineChart>
        )

        return (
          <Card.Grid
            key={`g${index}`}
            style={{ width: '50%', textAlign: 'center' }}
            hoverable={false}
          >
            <div className="flex flex-col items-center space-y-8">
              <Title level={5}>{genderName}</Title>
              <ResponsiveContainer width="80%" height={360}>
                {lineChart}
              </ResponsiveContainer>
            </div>
          </Card.Grid>
        )
      })}
    </Card>
  )
}

export default CrossAnalysisLineChart
