import { TochigiLayout } from '../layout/TochigiLayout'

import { useFetchReportsQuestionnaire } from '../../providers/TochigiProvider'
import { useEffect, useState } from 'react'
import QuestionnaireBarChart from '../components/QuestionnaireBarChart'
import { Select } from 'antd'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { getQuestionnaires } from '../../utils/const'

const { Option } = Select

type ReportQuestionnaireByGrade = {
  schoolCategory: string
  grade: number
  countByAnswer: number[]
}

type ReportQuestionnaireByGender = {
  gender: number
  grades: ReportQuestionnaireByGrade[]
}

export type ReportQuestionnaire = {
  key: string
  genders: ReportQuestionnaireByGender[]
}

const schools = [
  'elementarySchoolReport',
  'juniorHighSchoolReport',
  'highSchoolReport',
] as const

const ReportQuestionnaireTable = ({ year }: { year: number }) => {
  const { value: resArr, loading } = useFetchReportsQuestionnaire(year)

  const [dataForGraph, setDataForGraph] = useState<ReportQuestionnaire[]>([])

  useEffect(() => {
    if (!resArr || !resArr[0] || !resArr[1]) {
      setDataForGraph([])
      return
    }

    const userGenders = [1, 2]
    const _data: any[] = getQuestionnaires(year).map((q) => {
      const genders: any[] = [
        {
          gender: 1,
          grades: [],
        },
        {
          gender: 2,
          grades: [],
        },
      ]
      userGenders.forEach((g) => {
        const d = g === 1 ? resArr[0] : resArr[1]
        const destGrades = genders.find((gg) => gg.gender === g)
        if (!destGrades) return
        schools.forEach((s) => {
          if (!d) return
          const targetData = d[s]

          targetData.reports?.forEach((r) => {
            const { grade, countByAnswer } = r
            if (!countByAnswer || !countByAnswer[q.dataKey]) return
            let answers = countByAnswer[q.dataKey]

            const total = answers.reduce((p, c) => p + c.v, 0)
            answers = answers.map((v) => ((v.v / total) * 100).toFixed(1))
            let schoolCategory = 'ELEMENTARY_SCHOOL'
            if (s === 'juniorHighSchoolReport')
              schoolCategory = 'JUNIOR_HIGH_SCHOOL'
            else if (s === 'highSchoolReport') schoolCategory = 'HIGH_SCHOOL'

            destGrades.grades.push({
              schoolCategory,
              grade: grade!,
              countByAnswer: answers,
            })
          })
        })
      })
      return {
        key: q.dataKey,
        genders,
      }
    })
    setDataForGraph(_data)
  }, [resArr, loading])

  if (loading) {
    return <Loading />
  }

  if (dataForGraph.length === 0) {
    return <NoData />
  }

  return (
    <>
      {dataForGraph.map((q) => {
        const qq = getQuestionnaires(year).find((v) => v.dataKey === q.key)
        if (!qq) return null
        return (
          <QuestionnaireBarChart
            questionnaire={qq}
            report={q}
            key={q.key}
            forPrinting={false}
          />
        )
      })}
    </>
  )
}

export function ReportQuestionnairePage() {
  const [year, setYear] = useState(2024)
  return (
    <TochigiLayout>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <ReportQuestionnaireTable key={year} year={year} />
    </TochigiLayout>
  )
}
