import { Anchor, Divider } from '@sergeimeza/uikit-react'

import { TochigiLayout } from '../layout/TochigiLayout'

import Fitness from '../../assets/images/fitness.png'
import Challenge from '../../assets/images/challenge.png'

const menu = [
  {
    key: 'app',
    title: '体力診断ゾーン',
    pages: [
      {
        key: 'restore-password',
        name: 'パスワードを復元',
        to: '/restore-password',
      },
    ],
  },
  {
    key: 'upload',
    title: '新体力テスト＜アップロード＞',
    pages: [
      { key: 'file-uploads', name: 'アップロード情報', to: '/file-uploads' },
    ],
  },
  {
    key: 'reports',
    title: '新体力テスト＜レポート＞',
    pages: [
      { key: 'count', name: '調査人数', to: '/reports/count' },
      { key: 'body', name: '体格の平均値及び標準偏差', to: '/reports/body' },
      {
        key: 'test',
        name: '体力テスト測定項目の平均値と標準偏差',
        to: '/reports/test',
      },
      {
        key: 'test_rank',
        name: '総合評価段階別人数及びその割合',
        to: '/reports/test-rank',
      },
      {
        key: 'score_chart',
        name: 'Tスコアによる全国平均値による比較',
        to: '/reports/score-chart',
      },
      {
        key: 'test_comparison',
        name: '全国平均値との比較',
        to: '/reports/comparison',
      },
      {
        key: 'questionnaire',
        name: '児童生徒の生活状況',
        to: '/reports/questionnaire',
      },
      {
        key: 'cross_analysis',
        name: 'アンケート回答別クロス集計',
        to: '/reports/cross-analysis',
      },
      {
        key: 'cross_analysis_graph',
        name: 'アンケート回答別クロス集計(グラフ)',
        to: '/reports/cross-analysis-graph',
      },
      {
        key: 'school_performance',
        name: '新体力テスト優秀校表彰〈体力優秀校部門〉',
        to: '/reports/school-performance',
      },
      {
        key: 'school_performance_progress',
        name: '新体力テスト優秀校表彰〈体力向上校部門〉',
        to: '/reports/school-performance-progress',
      },
    ],
  },
]

const challenge = {
  key: 'challenge',
  title: 'チャレンジランキングゾーン',
  pages: [
    {
      key: 'challenges',
      name: 'チャレンジを管理',
      to: '/challenges',
    },
    {
      key: 'new-challenge',
      name: '新しいチャレンジを追加',
      to: '/challenges/new',
    },
  ],
}

export const HomePage = () => {
  return (
    <TochigiLayout>
      <header className="mb-10">
        <img className="w-72 mx-auto z-10" src={Fitness} alt="体力診断ゾーン" />
      </header>
      <div className="grid grid-cols-1 gap-y-8 mb-8">
        {menu.map((section) => (
          <div className="grid grid-cols-1 gap-y-4" key={section.key}>
            <h1 className="text-lg font-bold">{section.title}</h1>
            <div className="grid grid-cols-3 gap-4">
              {section.pages.map((page) => (
                <Anchor
                  key={page.key}
                  className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
                  to={page.to}
                >
                  {page.name}
                </Anchor>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Divider />
      <div className="my-8 z-10">
        <img src={Challenge} className="w-96 mx-auto mb-4" alt="" />
        <div className="grid grid-cols-3 gap-4">
          {challenge.pages.map((page) => (
            <Anchor
              key={page.key}
              className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
              to={page.to}
            >
              {page.name}
            </Anchor>
          ))}
        </div>
      </div>
    </TochigiLayout>
  )
}

export default HomePage
