import { Card } from 'antd'
import {
  ReportQuestionnaire,
} from '../pages/ReportQuestionnairePage'
import Title from 'antd/es/typography/Title'
import {
  BarChart,
  Bar,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts'
import { LayoutType, StackOffsetType } from 'recharts/types/util/types'
import { Questionnaire } from '../../utils/const'

const barColors = ['#AA0000', '#00AA00', '#0000AA', '#AA00AA']

const makeBarName = (c: string, grade: number): string => {
  switch (c) {
    case 'ELEMENTARY_SCHOOL':
      return `小${grade}`
    case 'JUNIOR_HIGH_SCHOOL':
      return `中${grade}`
    case 'HIGH_SCHOOL':
      return `高${grade}`
  }
  return ''
}
const domain = [0, 1]
const ticks = [0, 0.2, 0.4, 0.6, 0.8, 1]
const tickFormatter = (val, index) => {
  return val * 100 + '%'
}

type Props = {
  questionnaire: Questionnaire
  report: ReportQuestionnaire
  forPrinting: boolean
}

const QuestionnaireBarChart = ({
  questionnaire,
  report,
  forPrinting,
}: Props) => {
  return (
    <Card title={questionnaire.question} style={{ pageBreakAfter: 'always' }}>
      {report.genders.map((r) => {
        const gender = r.gender === 1 ? '男子' : '女子'
        const data = r.grades.map((reportGrade) => {
          const name = makeBarName(
            reportGrade.schoolCategory,
            reportGrade.grade,
          )
          const data = {
            name,
          }
          reportGrade.countByAnswer.forEach((v, k) => {
            const answer = questionnaire.answers[k]?.replace(/\(.*\)/i, '')
            data[answer] = v
          })
          return data
        })

        const legendFontSize = forPrinting ? 10 : 12
        const legendWrapperStyle = forPrinting
          ? { top: -14, fontSize: legendFontSize }
          : { fontSize: legendFontSize }
        const titleMarginBottom = forPrinting ? 16 : 0
        const barChartProps = {
          data: data,
          layout: 'vertical' as LayoutType,
          stackOffset: 'expand' as StackOffsetType,
          width: forPrinting ? 420 : 0,
          height: forPrinting ? 380 : 0,
        }

        const barChart = (
          <BarChart {...barChartProps}>
            <Legend verticalAlign="top" wrapperStyle={legendWrapperStyle} />
            <Tooltip />
            <XAxis
              type="number"
              domain={domain}
              tickCount={10}
              ticks={ticks}
              tickFormatter={tickFormatter}
            />
            <YAxis type="category" dataKey="name" />
            {questionnaire.answers.map((v, k) => {
              const answer = v.replace(/\(.*\)/i, '')
              return (
                <Bar
                  dataKey={answer}
                  stackId="A"
                  fill={barColors[k]}
                  isAnimationActive={!forPrinting}
                  key={k}
                />
              )
            })}
          </BarChart>
        )
        const withinContainer = (
          <ResponsiveContainer height={500} width="100%">
            {barChart}
          </ResponsiveContainer>
        )

        return (
          <Card.Grid
            style={{ width: forPrinting ? '100%' : '50%', textAlign: 'center' }}
            key={r.gender}
            hoverable={false}
          >
            <Title level={5} style={{ marginBottom: titleMarginBottom }}>
              {gender}
            </Title>
            {forPrinting ? (
              <div className="flex justify-center">{barChart}</div>
            ) : (
              withinContainer
            )}
          </Card.Grid>
        )
      })}
    </Card>
  )
}

export default QuestionnaireBarChart
