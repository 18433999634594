import { Select } from 'antd'
import { useState } from 'react'
import { useFetchReportsTestComparison } from '../../providers/TochigiProvider'
import { makeYearName } from '../../utils'
import { TochigiLayout } from '../layout/TochigiLayout'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'

type ComparisonYesNoGrade = {
  grip: boolean
  sitUps: boolean
  bending: boolean
  sideJump: boolean
  shuttleRun: boolean
  sprintRun: boolean
  standingJump: boolean
  handballThrow: boolean
  totalScore: boolean
}

type ComparisonYesNoSchool = {
  reports: {
    grade: number
    national: ComparisonYesNoGrade
  }[]
}

type ComparisonYesNo = {
  elementarySchoolReport: ComparisonYesNoSchool
  juniorHighSchoolReport: ComparisonYesNoSchool
  highSchoolReport: ComparisonYesNoSchool
}

const { Option } = Select

const styleForReport = {
  fontSize: 10,
  width: 90,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr className="border border-white">
        <th className={className} style={styleForReport}>
          校種
        </th>
        <th className={className} style={styleForReport}>
          学年
        </th>
        <th className={className} style={styleForReport}>
          握力
        </th>
        <th className={className} style={styleForReport}>
          上体起こし
        </th>
        <th className={className} style={styleForReport}>
          長座体前屈
        </th>
        <th className={className} style={styleForReport}>
          反復横跳び
        </th>
        <th className={className} style={styleForReport}>
          シャトルラン
        </th>
        <th className={className} style={styleForReport}>
          50m走
        </th>
        <th className={className} style={styleForReport}>
          立ち幅跳び
        </th>
        <th className={className} style={styleForReport}>
          ボール投げ
        </th>
        <th className={className} style={styleForReport}>
          得点合計
        </th>
      </tr>
    </thead>
  )
}

const TableBody = ({
  className,
  data,
  showType,
}: {
  className: string
  data: ComparisonYesNo
  showType: string
}) => {
  if (!data) return null

  return (
    <tbody>
      <tr>
        <th rowSpan={7} className={className}>
          小学校
        </th>
      </tr>
      {data.elementarySchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      <tr>
        <th rowSpan={4} className={className}>
          中学校
        </th>
      </tr>
      {data.juniorHighSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      <tr>
        <th rowSpan={4} className={className}>
          高校
        </th>
      </tr>
      {data.highSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
    </tbody>
  )
}

type ReportTableDataProps = {
  data: ComparisonYesNoSchool['reports'][0]
  showType: string
  className: string
}

const boolToString = (b: boolean) => (b ? '○' : '●')
const ReportTableData = ({
  data,
  showType,
  className,
}: ReportTableDataProps) => {
  const report = data[showType]

  return (
    <tr className={className}>
      <td className={className}>{data.grade}</td>
      <td className={className}>{boolToString(report.grip)}</td>
      <td className={className}>{boolToString(report.sitUps)}</td>
      <td className={className}>{boolToString(report.bending)}</td>
      <td className={className}>{boolToString(report.sideJump)}</td>
      <td className={className}>{boolToString(report.shuttleRun)}</td>
      <td className={className}>{boolToString(report.sprintRun)}</td>
      <td className={className}>{boolToString(report.standingJump)}</td>
      <td className={className}>{boolToString(report.handballThrow)}</td>
      <td className={className}>{boolToString(report.totalScore)}</td>
    </tr>
  )
}

type TableProps = {
  gender: 1 | 2
  year: number
}

const ReportTestComparisonTable = ({ gender, year }: TableProps) => {
  const {
    value: comparisonData,
    loading,
  }: { value: ComparisonYesNo; loading: boolean } =
    useFetchReportsTestComparison(gender, year)

  if (loading) {
    return <Loading />
  }

  if (!comparisonData) {
    return <NoData />
  }

  const tableClasses =
    gender === 1 ? 'table-border-male' : 'table-border-female'

  return (
    <div>
      <table className="table border-collapse">
        <TableHeader className={tableClasses} />
        <TableBody
          className={tableClasses}
          data={comparisonData}
          showType={'national'}
        />
      </table>
      <div>○→前年度以上</div>
      <div>●→前年度未満</div>
    </div>
  )
}
export function ReportTestComparisonPage() {
  const [year, setYear] = useState(2024)
  return (
    <TochigiLayout>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <div>男子</div>
      <ReportTestComparisonTable key={`male_${year}`} gender={1} year={year} />
      <div>女子</div>
      <ReportTestComparisonTable
        key={`female_${year}`}
        gender={2}
        year={year}
      />
    </TochigiLayout>
  )
}
