export enum UserGender {
  NotSet = 'NOT_SET',
  Male = 'MALE',
  Female = 'FEMALE',
}

export const Grip = {
  computeValue: (
    left1: number,
    left2: number,
    right1: number,
    right2: number,
  ) => {
    let left = Math.max(left1, left2)
    let right = Math.max(right1, right2)

    left = Math.floor(left)
    right = Math.floor(right)

    return Math.round((left + right) / 2)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = Grip.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = Grip.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = Grip.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = Grip.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = Grip.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = Grip.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = Grip.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = Grip.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = Grip.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = Grip.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [26, 1000] },
      9: { Range: [23, 25] },
      8: { Range: [20, 22] },
      7: { Range: [17, 19] },
      6: { Range: [14, 16] },
      5: { Range: [11, 13] },
      4: { Range: [9, 10] },
      3: { Range: [7, 8] },
      2: { Range: [5, 6] },
      1: { Range: [1, 4] },
    },
    FEMALE: {
      10: { Range: [25, 1000] },
      9: { Range: [22, 24] },
      8: { Range: [19, 21] },
      7: { Range: [16, 18] },
      6: { Range: [13, 15] },
      5: { Range: [11, 12] },
      4: { Range: [9, 10] },
      3: { Range: [7, 8] },
      2: { Range: [4, 6] },
      1: { Range: [1, 3] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [56, 1000] },
      9: { Range: [51, 55] },
      8: { Range: [47, 50] },
      7: { Range: [43, 46] },
      6: { Range: [38, 42] },
      5: { Range: [33, 37] },
      4: { Range: [28, 32] },
      3: { Range: [23, 27] },
      2: { Range: [18, 22] },
      1: { Range: [1, 17] },
    },
    FEMALE: {
      10: { Range: [36, 1000] },
      9: { Range: [33, 35] },
      8: { Range: [30, 32] },
      7: { Range: [28, 29] },
      6: { Range: [25, 27] },
      5: { Range: [23, 24] },
      4: { Range: [20, 22] },
      3: { Range: [17, 19] },
      2: { Range: [14, 16] },
      1: { Range: [1, 13] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [13, 1000] },
        4: { Range: [11, 12] },
        3: { Range: [8, 10] },
        2: { Range: [6, 7] },
        1: { Range: [0, 5] },
      },
      7: {
        5: { Range: [15, 1000] },
        4: { Range: [13, 14] },
        3: { Range: [10, 12] },
        2: { Range: [7, 9] },
        1: { Range: [0, 6] },
      },
      8: {
        5: { Range: [17, 1000] },
        4: { Range: [14, 16] },
        3: { Range: [11, 13] },
        2: { Range: [8, 10] },
        1: { Range: [0, 7] },
      },
      9: {
        5: { Range: [21, 1000] },
        4: { Range: [17, 20] },
        3: { Range: [13, 16] },
        2: { Range: [9, 12] },
        1: { Range: [0, 8] },
      },
      10: {
        5: { Range: [23, 1000] },
        4: { Range: [19, 22] },
        3: { Range: [15, 18] },
        2: { Range: [11, 14] },
        1: { Range: [0, 10] },
      },
      11: {
        5: { Range: [27, 1000] },
        4: { Range: [22, 26] },
        3: { Range: [17, 21] },
        2: { Range: [13, 16] },
        1: { Range: [0, 12] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [12, 1000] },
        4: { Range: [10, 11] },
        3: { Range: [8, 9] },
        2: { Range: [6, 7] },
        1: { Range: [0, 5] },
      },
      7: {
        5: { Range: [15, 1000] },
        4: { Range: [12, 14] },
        3: { Range: [9, 11] },
        2: { Range: [7, 8] },
        1: { Range: [0, 6] },
      },
      8: {
        5: { Range: [17, 1000] },
        4: { Range: [14, 16] },
        3: { Range: [11, 13] },
        2: { Range: [8, 10] },
        1: { Range: [0, 7] },
      },
      9: {
        5: { Range: [20, 1000] },
        4: { Range: [16, 19] },
        3: { Range: [12, 15] },
        2: { Range: [9, 11] },
        1: { Range: [0, 8] },
      },
      10: {
        5: { Range: [23, 1000] },
        4: { Range: [19, 22] },
        3: { Range: [15, 18] },
        2: { Range: [11, 14] },
        1: { Range: [0, 10] },
      },
      11: {
        5: { Range: [26, 1000] },
        4: { Range: [22, 25] },
        3: { Range: [17, 21] },
        2: { Range: [13, 16] },
        1: { Range: [0, 12] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [34, 1000] },
        4: { Range: [28, 33] },
        3: { Range: [22, 27] },
        2: { Range: [15, 21] },
        1: { Range: [0, 14] },
      },
      13: {
        5: { Range: [41, 1000] },
        4: { Range: [34, 40] },
        3: { Range: [26, 33] },
        2: { Range: [19, 25] },
        1: { Range: [0, 18] },
      },
      14: {
        5: { Range: [46, 1000] },
        4: { Range: [39, 45] },
        3: { Range: [31, 38] },
        2: { Range: [23, 30] },
        1: { Range: [0, 22] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [30, 1000] },
        4: { Range: [25, 29] },
        3: { Range: [20, 24] },
        2: { Range: [16, 19] },
        1: { Range: [0, 15] },
      },
      13: {
        5: { Range: [32, 1000] },
        4: { Range: [27, 31] },
        3: { Range: [22, 26] },
        2: { Range: [18, 21] },
        1: { Range: [0, 17] },
      },
      14: {
        5: { Range: [33, 1000] },
        4: { Range: [28, 32] },
        3: { Range: [23, 27] },
        2: { Range: [19, 22] },
        1: { Range: [0, 18] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [48, 1000] },
        4: { Range: [41, 47] },
        3: { Range: [34, 40] },
        2: { Range: [27, 33] },
        1: { Range: [0, 26] },
      },
      16: {
        5: { Range: [51, 1000] },
        4: { Range: [44, 50] },
        3: { Range: [36, 43] },
        2: { Range: [29, 35] },
        1: { Range: [0, 28] },
      },
      17: {
        5: { Range: [53, 1000] },
        4: { Range: [46, 52] },
        3: { Range: [38, 45] },
        2: { Range: [30, 37] },
        1: { Range: [0, 29] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [34, 1000] },
        4: { Range: [29, 33] },
        3: { Range: [24, 28] },
        2: { Range: [19, 23] },
        1: { Range: [0, 18] },
      },
      16: {
        5: { Range: [35, 1000] },
        4: { Range: [30, 34] },
        3: { Range: [24, 29] },
        2: { Range: [19, 23] },
        1: { Range: [0, 18] },
      },
      17: {
        5: { Range: [35, 1000] },
        4: { Range: [30, 34] },
        3: { Range: [25, 29] },
        2: { Range: [20, 24] },
        1: { Range: [0, 19] },
      },
    },
  },
  elementarySchoolComments: {
    5: '手、とくにゆびさきの動きには多くのしんけいがかかわっているため、手やゆびのうごきでかんかくしんけいや運動しんけいをしげきし、のうのはたらきをかっぱつにしましょう。すでにあくりょくの強いあなたは、ゆびさきをきように動かすことをいしきしながら、手・ゆびのかんかくをひつようとするトレーニングをおこなってみましょう。そのためには、せいかくなボールなげやバレーボールのオーバーハンドパスのようなトレーニングがゆうこうです。',
    4: '手、とくにゆびさきの動きには多くのしんけいがかかわっているため、手やゆびのうごきでかんかくしんけいや運動しんけいをしげきし、のうのはたらきをかっぱつにしましょう。すでにあくりょくの強いあなたは、ゆびさきをきように動かすことをいしきしながら、手・ゆびのかんかくをひつようとするトレーニングをおこなってみましょう。そのためには、せいかくなボールなげやバレーボールのオーバーハンドパスのようなトレーニングがゆうこうです。',
    3: 'あいてをつかむじゅうどうやすもう、自分をささえるてつぼうや棒高とび、道具をにぎるけんどうやテニスなど、多くのスポーツでひつようとされるのが、あくりょくです。あくりょくには、そのしゅんかんに強い力をはっきする場合と、長く使い続ける場合があります。全力でテニスボールをにぎってみたり、てつぼうにぶら下がれる時間をきそってみたり、力の出し方と時間のりょうほうのトレーニングをおこなってみましょう。',
    2: 'うんていや棒のぼり、つなや棒ひきなどをとおして、にぎる動作を日常的におこなってみましょう。これらの運動はきゅうに始めるとおりられなくなったり、手をけがしたりすることが多いですから十分に気を付けておこないましょう。また、家でもボールをにぎりしめたり、かぞくと一緒にタオルを引き合うなどのトレーニングもゆうこうです。',
    1: 'うんていや棒のぼり、つなや棒ひきなどをとおして、にぎる動作を日常的におこなってみましょう。これらの運動はきゅうに始めるとおりられなくなったり、手をけがしたりすることが多いですから十分に気を付けておこないましょう。また、家でもボールをにぎりしめたり、かぞくと一緒にタオルを引き合うなどのトレーニングもゆうこうです。',
  },
  middleSchoolComments: {
    5: '高い能力が見られますので、さらにタイミングについて考えてみましょう。握力は上肢の筋力の評価にも使用されます。相手の動きや物の動きに合わせ、力を発揮するタイミングを見極めることが大切です。リラックスした中で必要な瞬間だけに力を入れるなど、タイミングよく使えるような技術を身につけるとよいでしょう。',
    4: '高い能力が見られますので、さらにタイミングについて考えてみましょう。握力は上肢の筋力の評価にも使用されます。相手の動きや物の動きに合わせ、力を発揮するタイミングを見極めることが大切です。リラックスした中で必要な瞬間だけに力を入れるなど、タイミングよく使えるような技術を身につけるとよいでしょう。',
    3: '筋肉を上手く使うトレーニングを考えてみましょう。握力は上肢の筋力との関係が強いため、網や棒登り、うんてい、懸垂等、握力だけでなくほかの筋力も刺激するようなものがよいでしょう。筋力の飛躍的な発達は高校期以降ですが、筋肉量を増やすのではなく、うまく使うトレーニングが大切です。',
    2: '握力を高めるために、実施することを考えてみましょう。握力に使用される筋肉は、主として肘から手首までの部位の筋力です。特に上肢の筋力との関係が強いので、自分の体の重さを利用した腕立て伏せや懸垂などを実施するのがよいでしょう。高校期以降になると筋力が飛躍的に発達するでしょう。',
    1: '握力を高めるために、実施することを考えてみましょう。握力に使用される筋肉は、主として肘から手首までの部位の筋力です。特に上肢の筋力との関係が強いので、自分の体の重さを利用した腕立て伏せや懸垂などを実施するのがよいでしょう。高校期以降になると筋力が飛躍的に発達するでしょう。',
  },
  highSchoolComments: {
    5: 'さらに能力を活かすために、トレーニングを考えてみましょう。時間や角度、タイミングや指先の動きなど、実際のスポーツ動作を意識しながら実践に近いトレーニングを行うことが大切です。握力は、握る・つかむ・握ったものを保持する等の能力があるので、この能力を活かすとよいでしょう。',
    4: 'さらに能力を活かすために、トレーニングを考えてみましょう。時間や角度、タイミングや指先の動きなど、実際のスポーツ動作を意識しながら実践に近いトレーニングを行うことが大切です。握力は、握る・つかむ・握ったものを保持する等の能力があるので、この能力を活かすとよいでしょう。',
    3: '握力を高めるために、改善方法を考えてみましょう。握力は上半身の筋力との関連性が高いため、網登りや懸垂等の比較的負荷が大きいトレーニングをするのがよいでしょう。左右同じくらいの筋力はパフォーマンスを高めるため、力が出しにくい側の腕もしっかりとレーニンを行うことが大切です。',
    2: '握力を高めるために、トレーニングを考えてみましょう。上半身の筋力も影響しますが、高校生の時期に筋力は著しく発達するため、必要に応じてトレーニングをすることが大切です。両手を使う懸垂や綱登り、利き手ではない側の手も意識しながらトレーニングをするとよいでしょう。',
    1: '握力を高めるために、トレーニングを考えてみましょう。上半身の筋力も影響しますが、高校生の時期に筋力は著しく発達するため、必要に応じてトレーニングをすることが大切です。両手を使う懸垂や綱登り、利き手ではない側の手も意識しながらトレーニングをするとよいでしょう。',
  },
}

export const SitUps = {
  computeValue: (sitUps: number) => {
    return Math.round(sitUps)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = SitUps.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SitUps.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = SitUps.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SitUps.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = SitUps.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = SitUps.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = SitUps.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = SitUps.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = SitUps.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = SitUps.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [26, 1000] },
      9: { Range: [23, 25] },
      8: { Range: [20, 22] },
      7: { Range: [18, 19] },
      6: { Range: [15, 17] },
      5: { Range: [12, 14] },
      4: { Range: [9, 11] },
      3: { Range: [6, 8] },
      2: { Range: [3, 5] },
      1: { Range: [1, 2] },
    },
    FEMALE: {
      10: { Range: [23, 1000] },
      9: { Range: [20, 22] },
      8: { Range: [18, 19] },
      7: { Range: [16, 17] },
      6: { Range: [14, 15] },
      5: { Range: [12, 13] },
      4: { Range: [9, 11] },
      3: { Range: [6, 8] },
      2: { Range: [3, 5] },
      1: { Range: [1, 2] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [35, 1000] },
      9: { Range: [33, 34] },
      8: { Range: [30, 32] },
      7: { Range: [27, 29] },
      6: { Range: [25, 26] },
      5: { Range: [22, 24] },
      4: { Range: [19, 21] },
      3: { Range: [16, 18] },
      2: { Range: [13, 15] },
      1: { Range: [1, 12] },
    },
    FEMALE: {
      10: { Range: [29, 1000] },
      9: { Range: [26, 28] },
      8: { Range: [23, 25] },
      7: { Range: [20, 22] },
      6: { Range: [18, 19] },
      5: { Range: [15, 17] },
      4: { Range: [13, 14] },
      3: { Range: [11, 12] },
      2: { Range: [8, 10] },
      1: { Range: [1, 7] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [20, 1000] },
        4: { Range: [15, 19] },
        3: { Range: [10, 14] },
        2: { Range: [4, 9] },
        1: { Range: [0, 3] },
      },
      7: {
        5: { Range: [23, 1000] },
        4: { Range: [18, 22] },
        3: { Range: [12, 17] },
        2: { Range: [7, 11] },
        1: { Range: [0, 6] },
      },
      8: {
        5: { Range: [26, 1000] },
        4: { Range: [20, 25] },
        3: { Range: [14, 19] },
        2: { Range: [8, 13] },
        1: { Range: [0, 7] },
      },
      9: {
        5: { Range: [28, 1000] },
        4: { Range: [22, 27] },
        3: { Range: [16, 21] },
        2: { Range: [10, 15] },
        1: { Range: [0, 9] },
      },
      10: {
        5: { Range: [30, 1000] },
        4: { Range: [24, 29] },
        3: { Range: [18, 23] },
        2: { Range: [12, 17] },
        1: { Range: [0, 11] },
      },
      11: {
        5: { Range: [31, 1000] },
        4: { Range: [26, 30] },
        3: { Range: [20, 25] },
        2: { Range: [14, 19] },
        1: { Range: [0, 13] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [20, 1000] },
        4: { Range: [14, 19] },
        3: { Range: [9, 13] },
        2: { Range: [4, 8] },
        1: { Range: [0, 3] },
      },
      7: {
        5: { Range: [23, 1000] },
        4: { Range: [17, 22] },
        3: { Range: [12, 16] },
        2: { Range: [6, 11] },
        1: { Range: [0, 5] },
      },
      8: {
        5: { Range: [25, 1000] },
        4: { Range: [19, 24] },
        3: { Range: [14, 18] },
        2: { Range: [8, 13] },
        1: { Range: [0, 7] },
      },
      9: {
        5: { Range: [27, 1000] },
        4: { Range: [21, 26] },
        3: { Range: [15, 20] },
        2: { Range: [10, 14] },
        1: { Range: [0, 9] },
      },
      10: {
        5: { Range: [28, 1000] },
        4: { Range: [23, 27] },
        3: { Range: [17, 22] },
        2: { Range: [12, 16] },
        1: { Range: [0, 11] },
      },
      11: {
        5: { Range: [29, 1000] },
        4: { Range: [24, 28] },
        3: { Range: [19, 23] },
        2: { Range: [13, 18] },
        1: { Range: [0, 12] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [34, 1000] },
        4: { Range: [28, 33] },
        3: { Range: [22, 27] },
        2: { Range: [15, 21] },
        1: { Range: [0, 14] },
      },
      13: {
        5: { Range: [38, 1000] },
        4: { Range: [31, 37] },
        3: { Range: [25, 30] },
        2: { Range: [19, 24] },
        1: { Range: [0, 18] },
      },
      14: {
        5: { Range: [40, 1000] },
        4: { Range: [34, 39] },
        3: { Range: [27, 33] },
        2: { Range: [21, 26] },
        1: { Range: [0, 20] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [30, 1000] },
        4: { Range: [25, 29] },
        3: { Range: [19, 24] },
        2: { Range: [13, 18] },
        1: { Range: [0, 12] },
      },
      13: {
        5: { Range: [33, 1000] },
        4: { Range: [27, 32] },
        3: { Range: [21, 26] },
        2: { Range: [15, 20] },
        1: { Range: [0, 14] },
      },
      14: {
        5: { Range: [35, 1000] },
        4: { Range: [28, 34] },
        3: { Range: [22, 27] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [39, 1000] },
        4: { Range: [33, 38] },
        3: { Range: [27, 32] },
        2: { Range: [21, 26] },
        1: { Range: [0, 20] },
      },
      16: {
        5: { Range: [42, 1000] },
        4: { Range: [35, 41] },
        3: { Range: [29, 34] },
        2: { Range: [23, 28] },
        1: { Range: [0, 22] },
      },
      17: {
        5: { Range: [42, 1000] },
        4: { Range: [35, 41] },
        3: { Range: [29, 34] },
        2: { Range: [23, 28] },
        1: { Range: [0, 22] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [33, 1000] },
        4: { Range: [27, 32] },
        3: { Range: [21, 26] },
        2: { Range: [15, 20] },
        1: { Range: [0, 14] },
      },
      16: {
        5: { Range: [35, 1000] },
        4: { Range: [29, 34] },
        3: { Range: [22, 28] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
      17: {
        5: { Range: [35, 1000] },
        4: { Range: [29, 34] },
        3: { Range: [22, 28] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'あなたはふっきんとはいきんの使い方がうまく、きんにくを長く動かすのうりょくも高いので、たいかんの使い方も上手です。たいかんがうまく使えているといろいろなスポーツ場面でゆうこうでしょう。これからは、おきあがるさいにひざを引き付けるイメージをもって、ふっきんとももの前のきんにく（だいたいしとうきん）をうまくきょうちょうさせて使ってみたり、リズミカルな動きをいしきしたりして、さらにたいかんを高めていきましょう。',
    4: 'あなたはふっきんとはいきんの使い方がうまく、きんにくを長く動かすのうりょくも高いので、たいかんの使い方も上手です。たいかんがうまく使えているといろいろなスポーツ場面でゆうこうでしょう。これからは、おきあがるさいにひざを引き付けるイメージをもって、ふっきんとももの前のきんにく（だいたいしとうきん）をうまくきょうちょうさせて使ってみたり、リズミカルな動きをいしきしたりして、さらにたいかんを高めていきましょう。',
    3: 'あごを引いておなかを見ながら、丸くなるイメージでおきあがりましょう。おろすときにもあごは引いたままで、しせんもおなかをみたまま、こしからけんこうこつへとじゅんばんに地面につくように心がけましょう。はやくやろうとすると、背中にも力が入ってしまい、上体がまっすぐのびた動きになりがちなので気をつけましょう。',
    2: '上体おこしは主にきんじきゅうりょくをひょうかするものです。回数をあげるためには、たいかんの使い方やきんりょくを強くすることが大切です。おきあがるときに、背中の力をぬき、おなかだけに力を入れるようにすれば背中が丸くなっておきることができます。あごを引いて背中が丸くなるようにイメージしてチャレンジしてみましょう。',
    1: '上体おこしは主にきんじきゅうりょくをひょうかするものです。回数をあげるためには、たいかんの使い方やきんりょくを強くすることが大切です。おきあがるときに、背中の力をぬき、おなかだけに力を入れるようにすれば背中が丸くなっておきることができます。あごを引いて背中が丸くなるようにイメージしてチャレンジしてみましょう。',
  },
  middleSchoolComments: {
    5: 'あなたはふっきんとはいきんの使い方がうまく、きんにくを長く動かすのうりょくも高いので、たいかんの使い方も上手です。たいかんがうまく使えているといろいろなスポーツ場面でゆうこうでしょう。これからは、おきあがるさいにひざを引き付けるイメージをもって、ふっきんとももの前のきんにく（だいたいしとうきん）をうまくきょうちょうさせて使ってみたり、リズミカルな動きをいしきしたりして、さらにたいかんを高めていきましょう。',
    4: 'あなたはふっきんとはいきんの使い方がうまく、きんにくを長く動かすのうりょくも高いので、たいかんの使い方も上手です。たいかんがうまく使えているといろいろなスポーツ場面でゆうこうでしょう。これからは、おきあがるさいにひざを引き付けるイメージをもって、ふっきんとももの前のきんにく（だいたいしとうきん）をうまくきょうちょうさせて使ってみたり、リズミカルな動きをいしきしたりして、さらにたいかんを高めていきましょう。',
    3: '腹筋の働きをよりよく行うために、重要なことを考えてみましょう。腹筋は姿勢維持や上半身と下半身の動きを繋ぐ働きを持つ重要な筋肉です。筋力のバランスを大切にし、あごを引き胸元を見ながら背中を丸くする形で、しっかりと腹筋に力が入っていることを意識しながらトレーニングを行うとよいでしょう。',
    2: '高いパフォーマンスを発揮するために、いろいろな動きを行ってみましょう。単純に起き上がる動作だけでなく右肘を左膝へ、左肘を右膝へつける捻りを加えたものや、小さな動きを素早く行うのがよいでしょう。背中が反ると腰を痛めやすいので、必ずあごを引いて胸元を見ながら行うように注意が必要です。',
    1: '高いパフォーマンスを発揮するために、いろいろな動きを行ってみましょう。単純に起き上がる動作だけでなく右肘を左膝へ、左肘を右膝へつける捻りを加えたものや、小さな動きを素早く行うのがよいでしょう。背中が反ると腰を痛めやすいので、必ずあごを引いて胸元を見ながら行うように注意が必要です。',
  },
  highSchoolComments: {
    5: '高いパフォーマンスを発揮するために、いろいろな動きを行ってみましょう。身体を安定させる体幹の筋力を上手く使うためにスピードや角度、ひねりなどの動きを取り入れるとよいでしょう。あごを引いて背中を丸くしながら上体を起こすイメージで動作を行い、腰痛を予防することも大切です。',
    4: '高いパフォーマンスを発揮するために、いろいろな動きを行ってみましょう。身体を安定させる体幹の筋力を上手く使うためにスピードや角度、ひねりなどの動きを取り入れるとよいでしょう。あごを引いて背中を丸くしながら上体を起こすイメージで動作を行い、腰痛を予防することも大切です。',
    3: '腹筋の働きを発達させるために、トレーニングを考えてみましょう。腹筋は姿勢維持や上半身と下半身の動きを繋ぐ重要な働きを持つ筋肉です。軽い負荷で回数を多く行うトレーニングで発達させることが大切です。あごを引き背中を丸くしながら上体を起こすイメージで行うのがよいでしょう。',
    2: '腹筋力を高めるために、原因を考えてみましょう。腹筋の発達が影響することもありますが、腹筋を上手く使えるように意識するのがよいでしょう。あごを引き、胸元を見ながら上体を丸くするイメージで反復してみましょう。腹筋などの筋力は高校生の時期に発達するため、適切にトレーニングすることが大切です。',
    1: '腹筋力を高めるために、原因を考えてみましょう。腹筋の発達が影響することもありますが、腹筋を上手く使えるように意識するのがよいでしょう。あごを引き、胸元を見ながら上体を丸くするイメージで反復してみましょう。腹筋などの筋力は高校生の時期に発達するため、適切にトレーニングすることが大切です。',
  },
}

export const Bending = {
  computeValue: (bending1: number, bending2: number) => {
    let bending = Math.max(bending1, bending2)
    return Math.floor(bending)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = Bending.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = Bending.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = Bending.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = Bending.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = Bending.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = Bending.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = Bending.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = Bending.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = Bending.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = Bending.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [49, 1000] },
      9: { Range: [43, 48] },
      8: { Range: [38, 42] },
      7: { Range: [34, 37] },
      6: { Range: [30, 33] },
      5: { Range: [27, 29] },
      4: { Range: [23, 26] },
      3: { Range: [19, 22] },
      2: { Range: [15, 18] },
      1: { Range: [1, 14] },
    },
    FEMALE: {
      10: { Range: [52, 1000] },
      9: { Range: [46, 51] },
      8: { Range: [41, 45] },
      7: { Range: [37, 40] },
      6: { Range: [33, 36] },
      5: { Range: [29, 32] },
      4: { Range: [25, 28] },
      3: { Range: [21, 24] },
      2: { Range: [18, 20] },
      1: { Range: [1, 17] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [64, 1000] },
      9: { Range: [58, 63] },
      8: { Range: [53, 57] },
      7: { Range: [49, 52] },
      6: { Range: [44, 48] },
      5: { Range: [39, 43] },
      4: { Range: [33, 38] },
      3: { Range: [28, 32] },
      2: { Range: [21, 27] },
      1: { Range: [1, 20] },
    },
    FEMALE: {
      10: { Range: [63, 1000] },
      9: { Range: [58, 62] },
      8: { Range: [54, 57] },
      7: { Range: [50, 53] },
      6: { Range: [45, 49] },
      5: { Range: [40, 44] },
      4: { Range: [35, 39] },
      3: { Range: [30, 34] },
      2: { Range: [23, 29] },
      1: { Range: [1, 22] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [39, 1000] },
        4: { Range: [31, 38] },
        3: { Range: [24, 30] },
        2: { Range: [16, 23] },
        1: { Range: [0, 15] },
      },
      7: {
        5: { Range: [41, 1000] },
        4: { Range: [33, 40] },
        3: { Range: [24, 32] },
        2: { Range: [17, 23] },
        1: { Range: [0, 16] },
      },
      8: {
        5: { Range: [43, 1000] },
        4: { Range: [35, 42] },
        3: { Range: [26, 34] },
        2: { Range: [18, 25] },
        1: { Range: [0, 17] },
      },
      9: {
        5: { Range: [45, 1000] },
        4: { Range: [36, 44] },
        3: { Range: [28, 35] },
        2: { Range: [20, 27] },
        1: { Range: [0, 19] },
      },
      10: {
        5: { Range: [47, 1000] },
        4: { Range: [39, 46] },
        3: { Range: [30, 38] },
        2: { Range: [21, 29] },
        1: { Range: [0, 20] },
      },
      11: {
        5: { Range: [49, 1000] },
        4: { Range: [40, 48] },
        3: { Range: [32, 39] },
        2: { Range: [23, 31] },
        1: { Range: [0, 22] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [41, 1000] },
        4: { Range: [33, 40] },
        3: { Range: [25, 32] },
        2: { Range: [18, 24] },
        1: { Range: [0, 17] },
      },
      7: {
        5: { Range: [43, 1000] },
        4: { Range: [36, 42] },
        3: { Range: [28, 35] },
        2: { Range: [20, 27] },
        1: { Range: [0, 19] },
      },
      8: {
        5: { Range: [46, 1000] },
        4: { Range: [37, 45] },
        3: { Range: [29, 36] },
        2: { Range: [21, 28] },
        1: { Range: [0, 20] },
      },
      9: {
        5: { Range: [48, 1000] },
        4: { Range: [40, 47] },
        3: { Range: [31, 39] },
        2: { Range: [23, 30] },
        1: { Range: [0, 22] },
      },
      10: {
        5: { Range: [52, 1000] },
        4: { Range: [43, 51] },
        3: { Range: [34, 42] },
        2: { Range: [25, 33] },
        1: { Range: [0, 24] },
      },
      11: {
        5: { Range: [54, 1000] },
        4: { Range: [45, 53] },
        3: { Range: [36, 44] },
        2: { Range: [27, 35] },
        1: { Range: [0, 26] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [56, 1000] },
        4: { Range: [46, 55] },
        3: { Range: [36, 45] },
        2: { Range: [26, 35] },
        1: { Range: [0, 25] },
      },
      13: {
        5: { Range: [62, 1000] },
        4: { Range: [51, 61] },
        3: { Range: [40, 50] },
        2: { Range: [29, 39] },
        1: { Range: [0, 28] },
      },
      14: {
        5: { Range: [66, 1000] },
        4: { Range: [54, 65] },
        3: { Range: [43, 53] },
        2: { Range: [32, 42] },
        1: { Range: [0, 31] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [60, 1000] },
        4: { Range: [50, 59] },
        3: { Range: [39, 49] },
        2: { Range: [29, 38] },
        1: { Range: [0, 28] },
      },
      13: {
        5: { Range: [63, 1000] },
        4: { Range: [53, 62] },
        3: { Range: [42, 52] },
        2: { Range: [32, 41] },
        1: { Range: [0, 31] },
      },
      14: {
        5: { Range: [65, 1000] },
        4: { Range: [55, 64] },
        3: { Range: [44, 54] },
        2: { Range: [34, 43] },
        1: { Range: [0, 33] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [63, 1000] },
        4: { Range: [52, 62] },
        3: { Range: [42, 51] },
        2: { Range: [31, 41] },
        1: { Range: [0, 30] },
      },
      16: {
        5: { Range: [66, 1000] },
        4: { Range: [55, 65] },
        3: { Range: [44, 54] },
        2: { Range: [33, 43] },
        1: { Range: [0, 32] },
      },
      17: {
        5: { Range: [68, 1000] },
        4: { Range: [57, 67] },
        3: { Range: [45, 56] },
        2: { Range: [34, 44] },
        1: { Range: [0, 33] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [63, 1000] },
        4: { Range: [53, 62] },
        3: { Range: [43, 52] },
        2: { Range: [33, 42] },
        1: { Range: [0, 32] },
      },
      16: {
        5: { Range: [63, 1000] },
        4: { Range: [53, 62] },
        3: { Range: [43, 52] },
        2: { Range: [34, 42] },
        1: { Range: [0, 33] },
      },
      17: {
        5: { Range: [65, 1000] },
        4: { Range: [54, 64] },
        3: { Range: [44, 53] },
        2: { Range: [34, 43] },
        1: { Range: [0, 33] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'じゅうなんせいは、スポーツのしゅもくやじょうきょうによってことなり、きんにくを使わなければじゅうなんせいが失われてしまいます。そのため、おこなうスポーツの動きを考え、じっさいの動きにひつようなじゅうなんせいをにちじょうてきに高めていきましょう。からだを温め、いきをはいてリラックスしながら、ゆっくりと体をのばし、そのまま１５～３０びょうていどいじするストレッチをおこなってみましょう。',
    4: 'じゅうなんせいは、スポーツのしゅもくやじょうきょうによってことなり、きんにくを使わなければじゅうなんせいが失われてしまいます。そのため、おこなうスポーツの動きを考え、じっさいの動きにひつようなじゅうなんせいをにちじょうてきに高めていきましょう。からだを温め、いきをはいてリラックスしながら、ゆっくりと体をのばし、そのまま１５～３０びょうていどいじするストレッチをおこなってみましょう。',
    3: 'じゅうなんせいを高めるには２つのストレッチを行いましょう。一つめはゆっくりときんにくをのばし、いたくないていどのいちで１５～３０びょうていどいじするせいてきストレッチです。二つめははんどうをつけずにかんせつが動くはんいをさいだいげん、くりかえし動かしていくどうてきストレッチです。からだを温め、リラックスしながらこきゅうをはくことをいしきしてこの2つのストレッチをおこなうことで、しょうがい発生のよぼうや運動中のよりスムーズな動きを助けるじゅうなんせいを身につけましょう。',
    2: 'じゅうなんせいは人によってちがうため、友だちとひかくするのではなく、自分のじゅうなんせいがどのように変化したかを感じるようにしましょう。まずはからだ（きんにく）をうんどうやにゅうよくでよくあたためましょう。こきゅうを大きくはきながら、いきおいをつけずにゆっくりときんにくをのばしてみるといいですね。いっぱんてきには１５びょうから３０びょうていどそのままいじするとよいといわれていますので、チャレンジしてみましょう。',
    1: 'じゅうなんせいは人によってちがうため、友だちとひかくするのではなく、自分のじゅうなんせいがどのように変化したかを感じるようにしましょう。まずはからだ（きんにく）をうんどうやにゅうよくでよくあたためましょう。こきゅうを大きくはきながら、いきおいをつけずにゆっくりときんにくをのばしてみるといいですね。いっぱんてきには１５びょうから３０びょうていどそのままいじするとよいといわれていますので、チャレンジしてみましょう。',
  },
  middleSchoolComments: {
    5: '柔軟性を維持するためにストレッチを行いましょう。痛みを感じない程度まで伸ばし、１０～３０秒程度息を止めずに維持しましょう。お風呂上りに色々な筋肉のストレッチを継続的に行うと効果的です。現在は高い柔軟性がありますが、急激な成長により柔軟性を失うことのないよう腰痛などのスポーツ障害を誘発しないために行うとよいでしょう。',
    4: '柔軟性を維持するためにストレッチを行いましょう。痛みを感じない程度まで伸ばし、１０～３０秒程度息を止めずに維持しましょう。お風呂上りに色々な筋肉のストレッチを継続的に行うと効果的です。現在は高い柔軟性がありますが、急激な成長により柔軟性を失うことのないよう腰痛などのスポーツ障害を誘発しないために行うとよいでしょう。',
    3: 'スポーツ障害を誘発しないために、ストレッチを行いましょう。今後の発達で筋肉の緊張が高まり、柔軟性を失うと腰痛などを誘発する可能性があります。呼吸を止めずに痛みを感じない程度まで伸ばし、１０～３０秒維持する要領でストレッチを行いましょう。お風呂上りに色々な筋肉のストレッチを継続的に行うことも効果的です。',
    2: '柔軟性を高めるために、行うことを考えてみましょう。呼吸を止めずに痛みを感じない程度まで伸ばし、１０～３０秒維持する要領でストレッチを行いましょう。筋肉を痛める可能性を低くし、ほかの部位の柔軟性や関節の可動域を高くするためにも、お風呂上りに色々な筋肉のストレッチを継続的に行うとよいでしょう。',
    1: '柔軟性を高めるために、行うことを考えてみましょう。呼吸を止めずに痛みを感じない程度まで伸ばし、１０～３０秒維持する要領でストレッチを行いましょう。筋肉を痛める可能性を低くし、ほかの部位の柔軟性や関節の可動域を高くするためにも、お風呂上りに色々な筋肉のストレッチを継続的に行うとよいでしょう。',
  },
  highSchoolComments: {
    5: 'さらに柔軟性を高めるに、ストレッチを行いましょう。いろいろな筋肉を痛みを感じない程度まで伸ばし、１０秒～３０秒程度息を止めずに維持するのがよいでしょう。急激な成長等で柔軟性が低下しないように継続的にストレッチすることが大切です。',
    4: 'さらに柔軟性を高めるに、ストレッチを行いましょう。いろいろな筋肉を痛みを感じない程度まで伸ばし、１０秒～３０秒程度息を止めずに維持するのがよいでしょう。急激な成長等で柔軟性が低下しないように継続的にストレッチすることが大切です。',
    3: 'スポーツ障害を誘発しないために、ストレッチを行いましょう。今後の発達で筋肉の緊張が高まることにより、柔軟性を失わないようにするのがよいでしょう。呼吸を止めずに痛みを感じない程度まで伸ばし、１０～３０秒維持する要領で、体のいろいろな筋肉を継続的にストレッチすることが大切です。',
    2: '柔軟性を高めるために行うことを考えてみましょう。１０～３０秒、全身ゆっくりとストレッチを行うとよいでしょう。運動前後にもお風呂上りなどの筋温が高いときにも行い、日常的、継続的に行うことが大切です。柔軟性はすぐに高まりにくいのですが、続けることで必ず向上します。',
    1: '柔軟性を高めるために行うことを考えてみましょう。１０～３０秒、全身ゆっくりとストレッチを行うとよいでしょう。運動前後にもお風呂上りなどの筋温が高いときにも行い、日常的、継続的に行うことが大切です。柔軟性はすぐに高まりにくいのですが、続けることで必ず向上します。',
  },
}

export const SideJump = {
  computeValue: (sideJump1: number, sideJump2: number) => {
    let sideJump = Math.max(sideJump1, sideJump2)
    return Math.floor(sideJump)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = SideJump.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SideJump.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = SideJump.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SideJump.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = SideJump.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = SideJump.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = SideJump.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = SideJump.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = SideJump.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = SideJump.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [50, 1000] },
      9: { Range: [46, 49] },
      8: { Range: [42, 45] },
      7: { Range: [38, 41] },
      6: { Range: [34, 37] },
      5: { Range: [30, 33] },
      4: { Range: [26, 29] },
      3: { Range: [22, 25] },
      2: { Range: [18, 21] },
      1: { Range: [1, 17] },
    },
    FEMALE: {
      10: { Range: [47, 1000] },
      9: { Range: [43, 46] },
      8: { Range: [40, 42] },
      7: { Range: [36, 39] },
      6: { Range: [32, 35] },
      5: { Range: [28, 31] },
      4: { Range: [25, 27] },
      3: { Range: [21, 24] },
      2: { Range: [17, 20] },
      1: { Range: [1, 16] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [63, 1000] },
      9: { Range: [60, 62] },
      8: { Range: [56, 59] },
      7: { Range: [53, 55] },
      6: { Range: [49, 52] },
      5: { Range: [45, 48] },
      4: { Range: [41, 44] },
      3: { Range: [37, 40] },
      2: { Range: [30, 36] },
      1: { Range: [1, 29] },
    },
    FEMALE: {
      10: { Range: [53, 1000] },
      9: { Range: [50, 52] },
      8: { Range: [48, 49] },
      7: { Range: [45, 47] },
      6: { Range: [42, 44] },
      5: { Range: [39, 41] },
      4: { Range: [36, 38] },
      3: { Range: [32, 35] },
      2: { Range: [27, 31] },
      1: { Range: [1, 26] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [36, 1000] },
        4: { Range: [30, 35] },
        3: { Range: [24, 29] },
        2: { Range: [18, 23] },
        1: { Range: [0, 17] },
      },
      7: {
        5: { Range: [42, 1000] },
        4: { Range: [34, 41] },
        3: { Range: [27, 33] },
        2: { Range: [20, 26] },
        1: { Range: [0, 19] },
      },
      8: {
        5: { Range: [47, 1000] },
        4: { Range: [39, 46] },
        3: { Range: [30, 38] },
        2: { Range: [22, 29] },
        1: { Range: [0, 21] },
      },
      9: {
        5: { Range: [51, 1000] },
        4: { Range: [43, 50] },
        3: { Range: [34, 42] },
        2: { Range: [26, 33] },
        1: { Range: [0, 25] },
      },
      10: {
        5: { Range: [55, 1000] },
        4: { Range: [47, 54] },
        3: { Range: [38, 46] },
        2: { Range: [30, 37] },
        1: { Range: [0, 29] },
      },
      11: {
        5: { Range: [57, 1000] },
        4: { Range: [49, 56] },
        3: { Range: [42, 48] },
        2: { Range: [34, 41] },
        1: { Range: [0, 33] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [34, 1000] },
        4: { Range: [29, 33] },
        3: { Range: [23, 28] },
        2: { Range: [18, 22] },
        1: { Range: [0, 17] },
      },
      7: {
        5: { Range: [40, 1000] },
        4: { Range: [33, 39] },
        3: { Range: [26, 32] },
        2: { Range: [19, 25] },
        1: { Range: [0, 18] },
      },
      8: {
        5: { Range: [44, 1000] },
        4: { Range: [37, 43] },
        3: { Range: [29, 36] },
        2: { Range: [21, 28] },
        1: { Range: [0, 20] },
      },
      9: {
        5: { Range: [48, 1000] },
        4: { Range: [41, 47] },
        3: { Range: [33, 40] },
        2: { Range: [26, 32] },
        1: { Range: [0, 25] },
      },
      10: {
        5: { Range: [52, 1000] },
        4: { Range: [45, 51] },
        3: { Range: [37, 44] },
        2: { Range: [30, 36] },
        1: { Range: [0, 29] },
      },
      11: {
        5: { Range: [54, 1000] },
        4: { Range: [47, 53] },
        3: { Range: [40, 46] },
        2: { Range: [33, 39] },
        1: { Range: [0, 32] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [62, 1000] },
        4: { Range: [54, 61] },
        3: { Range: [46, 53] },
        2: { Range: [38, 45] },
        1: { Range: [0, 37] },
      },
      13: {
        5: { Range: [66, 1000] },
        4: { Range: [58, 65] },
        3: { Range: [49, 57] },
        2: { Range: [41, 48] },
        1: { Range: [0, 40] },
      },
      14: {
        5: { Range: [69, 1000] },
        4: { Range: [60, 68] },
        3: { Range: [52, 59] },
        2: { Range: [44, 51] },
        1: { Range: [0, 43] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [57, 1000] },
        4: { Range: [50, 56] },
        3: { Range: [43, 49] },
        2: { Range: [36, 42] },
        1: { Range: [0, 35] },
      },
      13: {
        5: { Range: [59, 1000] },
        4: { Range: [52, 58] },
        3: { Range: [45, 51] },
        2: { Range: [38, 44] },
        1: { Range: [0, 37] },
      },
      14: {
        5: { Range: [60, 1000] },
        4: { Range: [53, 59] },
        3: { Range: [45, 52] },
        2: { Range: [38, 44] },
        1: { Range: [0, 37] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [67, 1000] },
        4: { Range: [60, 66] },
        3: { Range: [52, 59] },
        2: { Range: [45, 51] },
        1: { Range: [0, 44] },
      },
      16: {
        5: { Range: [69, 1000] },
        4: { Range: [61, 68] },
        3: { Range: [54, 60] },
        2: { Range: [47, 53] },
        1: { Range: [0, 46] },
      },
      17: {
        5: { Range: [70, 1000] },
        4: { Range: [62, 69] },
        3: { Range: [54, 61] },
        2: { Range: [46, 53] },
        1: { Range: [0, 45] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [58, 1000] },
        4: { Range: [52, 57] },
        3: { Range: [45, 51] },
        2: { Range: [39, 44] },
        1: { Range: [0, 38] },
      },
      16: {
        5: { Range: [58, 1000] },
        4: { Range: [52, 57] },
        3: { Range: [45, 51] },
        2: { Range: [39, 44] },
        1: { Range: [0, 38] },
      },
      17: {
        5: { Range: [58, 1000] },
        4: { Range: [52, 57] },
        3: { Range: [45, 51] },
        2: { Range: [39, 44] },
        1: { Range: [0, 38] },
      },
    },
  },
  elementarySchoolComments: {
    5: '日ごろのあそびの中で友だちをおいかけたり、ボールをつかった色々な方こうへの動きをおこなってみましょう。そうすることで、スポーツかつどうに必要なあいてやボールの動きや変化するものに対するはんのうやはんだんする力が身につきます。はんぷく横とびにひつような、リズムかん、下半身のバネ、あしをすばやく動かすためのしんけいけいのうりょくなどはうまくりようできているようです。',
    4: '日ごろのあそびの中で友だちをおいかけたり、ボールをつかった色々な方こうへの動きをおこなってみましょう。そうすることで、スポーツかつどうに必要なあいてやボールの動きや変化するものに対するはんのうやはんだんする力が身につきます。はんぷく横とびにひつような、リズムかん、下半身のバネ、あしをすばやく動かすためのしんけいけいのうりょくなどはうまくりようできているようです。',
    3: 'はんぷく横とびにひつようなびんしょうせいというのうりょくは、リズムかん、下半身のバネ、あしをはやく動かすためのしんけいけいのうりょくを使ってはったつすることができます。リズムかんはなわとび・けんけんぱ、下半身のバネはなわとび・馬とび、しんけいけいは鬼ごっこなどの遊びで身につきます。あなたが友だちよりもにがてだなと思うあそびをおこなって、びんしょうせいを高めましょう。',
    2: 'はんぷく横とびの動きがわからない場合は、まず動く回数を増やしてみましょう。5回横に飛んで、5回もどる動きをくり返し、できたら4回・3回とへらしていきましょう。動きがわかっている場合は、左右のせんをふんでからもどる時のタイミングをはやくするために何度もれんしゅうをし、記録を高めていきましょう。',
    1: 'はんぷく横とびの動きがわからない場合は、まず動く回数を増やしてみましょう。5回横に飛んで、5回もどる動きをくり返し、できたら4回・3回とへらしていきましょう。動きがわかっている場合は、左右のせんをふんでからもどる時のタイミングをはやくするために何度もれんしゅうをし、記録を高めていきましょう。',
  },
  middleSchoolComments: {
    5: 'すでに高い能力が見られますので、方向変換するときのトレーニングを考えてみましょう。方向を変換するときを意識し、一瞬止まる時間（方向変換にかかる時間）を短くするためには、リラックスして次の動作を予測した動きを行えるようになるとよいでしょう。方向変換の際に必要となるバネ的な能力を獲得するためには、ジャンプ系のトレーニングが効果的です。この動作はリズム感のトレーニングにもなります。',
    4: 'すでに高い能力が見られますので、方向変換するときのトレーニングを考えてみましょう。方向を変換するときを意識し、一瞬止まる時間（方向変換にかかる時間）を短くするためには、リラックスして次の動作を予測した動きを行えるようになるとよいでしょう。方向変換の際に必要となるバネ的な能力を獲得するためには、ジャンプ系のトレーニングが効果的です。この動作はリズム感のトレーニングにもなります。',
    3: 'トレーニング動作の意識を考えてみましょう。反復横跳びは左右の方向変換が必要となります。身体全体を左右に動かすのではなく、下半身を中心に動かし、無駄な動きが少ない動作を意識するのがよいでしょう。こうした動きは、身体の中心（重心）位置の把握やリズム感のトレーニングに繋がりますから、意識をすることが大切です。',
    2: '反復横跳びに必要な動作を考えてみましょう。身体を速く動かすのが少し苦手なことも考えられますが、反復横跳びは左右に方向を変換するための脚力が必要となります。頭の中で「1・2・1・2・・・」と数えながら、左右に方向を変換しましょう。両サイドラインの「1」の時を特に強調し、テンポよくリズミカルな動作をとるのがよいでしょう。',
    1: '反復横跳びに必要な動作を考えてみましょう。身体を速く動かすのが少し苦手なことも考えられますが、反復横跳びは左右に方向を変換するための脚力が必要となります。頭の中で「1・2・1・2・・・」と数えながら、左右に方向を変換しましょう。両サイドラインの「1」の時を特に強調し、テンポよくリズミカルな動作をとるのがよいでしょう。',
  },
  highSchoolComments: {
    5: 'さらに能力を高めるために、必要なことを考えてみましょう。敏捷性を高めるためにはリラックスしてタイミングよく力を発揮することや効率的な重心移動が必要となります。方向変換が速くなるよう、下半身を中心とした動き作りや、ジャンプトレーニング等で方向変換に必要となる下半身のバネを身につけるとよいでしょう。',
    4: 'さらに能力を高めるために、必要なことを考えてみましょう。敏捷性を高めるためにはリラックスしてタイミングよく力を発揮することや効率的な重心移動が必要となります。方向変換が速くなるよう、下半身を中心とした動き作りや、ジャンプトレーニング等で方向変換に必要となる下半身のバネを身につけるとよいでしょう。',
    3: 'トレーニング動作の意識を考えてみましょう。反復横跳は左右の方向変換が必要となります。身体全体を左右に動かすのではなく、下半身を中心に動かし、無駄な動きが少ない動作を意識するのがよいでしょう。そうすることで身体の中心（重心）位置の把握やリズム感のトレーニングに繋がりますから、意識をすることが大切です。',
    2: '意識とトレーニングを考えてみましょう。リラックスしながら、頭の中で「１・２・１・２…」と数えながら、方向変換のタイミングを意識するとよいでしょう。方向変換に要する時間短縮を心がけることも大切です。敏捷性には下半身のバネも必要になるため、リラックスして接地時間を短くし、滞空時間を長く意識した縄跳びによるジャンプトレーニングやリズム感のトレーニング等が効果的でしょう。',
    1: '意識とトレーニングを考えてみましょう。リラックスしながら、頭の中で「１・２・１・２…」と数えながら、方向変換のタイミングを意識するとよいでしょう。方向変換に要する時間短縮を心がけることも大切です。敏捷性には下半身のバネも必要になるため、リラックスして接地時間を短くし、滞空時間を長く意識した縄跳びによるジャンプトレーニングやリズム感のトレーニング等が効果的でしょう。',
  },
}

export const EnduranceRun = {
  computeValue: (time: number) => {
    let seconds = Math.ceil(time)
    return Math.floor(seconds)
  },
  computePoints: (value: number, age, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        return 0
      }
      let ranges = EnduranceRun.middleSchoolRanges.MALE

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          points = Number(key)
        }
      }
    } else {
      let ranges = EnduranceRun.middleSchoolRanges.FEMALE

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          points = Number(key)
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(12, Math.min(17, age))

    if (age < 15) {
      let ranges = EnduranceRun.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = EnduranceRun.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 15) {
      let ranges = EnduranceRun.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = EnduranceRun.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  middleSchoolRanges: {
    MALE: {
      1: { Range: [561, 10_000] },
      2: { Range: [500, 560] },
      3: { Range: [451, 499] },
      4: { Range: [411, 450] },
      5: { Range: [383, 410] },
      6: { Range: [356, 382] },
      7: { Range: [334, 355] },
      8: { Range: [317, 333] },
      9: { Range: [300, 316] },
      10: { Range: [1, 299] },
    },
    FEMALE: {
      1: { Range: [418, 10_000] },
      2: { Range: [375, 417] },
      3: { Range: [343, 374] },
      4: { Range: [319, 342] },
      5: { Range: [297, 318] },
      6: { Range: [278, 296] },
      7: { Range: [260, 277] },
      8: { Range: [243, 259] },
      9: { Range: [230, 242] },
      10: { Range: [1, 229] },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        1: { Range: [553, 10_000] },
        2: { Range: [474, 552] },
        3: { Range: [396, 473] },
        4: { Range: [317, 395] },
        5: { Range: [1, 316] },
      },
      13: {
        1: { Range: [517, 10_000] },
        2: { Range: [439, 516] },
        3: { Range: [362, 438] },
        4: { Range: [284, 361] },
        5: { Range: [1, 283] },
      },
      14: {
        1: { Range: [462, 10_000] },
        2: { Range: [406, 461] },
        3: { Range: [350, 405] },
        4: { Range: [294, 349] },
        5: { Range: [1, 293] },
      },
    },
    FEMALE: {
      12: {
        1: { Range: [379, 10_000] },
        2: { Range: [329, 378] },
        3: { Range: [279, 328] },
        4: { Range: [229, 278] },
        5: { Range: [1, 228] },
      },
      13: {
        1: { Range: [374, 10_000] },
        2: { Range: [319, 373] },
        3: { Range: [264, 318] },
        4: { Range: [209, 263] },
        5: { Range: [1, 208] },
      },
      14: {
        1: { Range: [362, 10_000] },
        2: { Range: [316, 361] },
        3: { Range: [269, 315] },
        4: { Range: [223, 268] },
        5: { Range: [1, 222] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        1: { Range: [490, 10_000] },
        2: { Range: [428, 489] },
        3: { Range: [367, 427] },
        4: { Range: [305, 366] },
        5: { Range: [1, 304] },
      },
      16: {
        1: { Range: [483, 10_000] },
        2: { Range: [419, 482] },
        3: { Range: [354, 418] },
        4: { Range: [290, 353] },
        5: { Range: [1, 289] },
      },
      17: {
        1: { Range: [500, 10_000] },
        2: { Range: [428, 499] },
        3: { Range: [356, 427] },
        4: { Range: [284, 355] },
        5: { Range: [1, 283] },
      },
    },
    FEMALE: {
      15: {
        1: { Range: [389, 10_000] },
        2: { Range: [342, 388] },
        3: { Range: [494, 341] },
        4: { Range: [247, 493] },
        5: { Range: [1, 246] },
      },
      16: {
        1: { Range: [387, 10_000] },
        2: { Range: [337, 386] },
        3: { Range: [288, 336] },
        4: { Range: [238, 287] },
        5: { Range: [1, 237] },
      },
      17: {
        1: { Range: [402, 10_000] },
        2: { Range: [346, 401] },
        3: { Range: [289, 345] },
        4: { Range: [232, 288] },
        5: { Range: [1, 231] },
      },
    },
  },
  middleSchoolComments: {
    1: '持久力を高めるために、できることを考えてみましょう。まずはきつくなったら歩く、回復したら走るのを繰り返して３０分程度の継続を目標とし、慣れてきたら歩く距離を少なくしながら３０分のジョギングができるようになりましょう。階段上りや徒歩など、日常的に体を動かす機会を増やすとともに、長時間運動が継続できるようになるとよいでしょう。',
    2: '持久力を高めるために、できることを考えてみましょう。まずはきつくなったら歩く、回復したら走るのを繰り返して３０分程度の継続を目標とし、慣れてきたら歩く距離を少なくしながら３０分のジョギングができるようになりましょう。階段上りや徒歩など、日常的に体を動かす機会を増やすとともに、長時間運動が継続できるようになるとよいでしょう。',
    3: '持久力を伸ばすために、トレーニングを考えてみましょう。３０分程度のジョギングを目標とし、慣れてきたら途中で速度を上げ、きつくなったら止まらずに、ゆっくりしたジョギングに戻すといった繰り返し（インターバルトレーニング）を行うことも効果的です。持久力は、中学期に伸ばすとよい能力なので、体格の発達にあわせ、肺や毛細血管も発達させるとよいでしょう。',
    4: 'すでに高い能力が見られますので、持久力をさらに伸ばすために、トレーニングを考えてみましょう。長い距離をゆっくり長時間走ることや、スピードを上げたり下げたりしながら走るインターバルトレーニング等を行いながら、定期的に記録を測定しましょう。目標をもって計画的なトレーニングを実施し、努力するとよいでしょう。',
    5: 'すでに高い能力が見られますので、持久力をさらに伸ばすために、トレーニングを考えてみましょう。長い距離をゆっくり長時間走ることや、スピードを上げたり下げたりしながら走るインターバルトレーニング等を行いながら、定期的に記録を測定しましょう。目標をもって計画的なトレーニングを実施し、努力するとよいでしょう。',
  },
  highSchoolComments: {
    1: '持久力を高めるために、大切なことを考えてみましょう。階段登りやウォーキングなど日常生活で体を動かす機会を増やすことが大切です。ジョギングとウォーキングを交互に繰り返しながら３０分程度の継続を目標とし、慣れたらジョギングで３０分間の運動ができるようになるとよいでしょう。持久力は日常生活の疲労回復にも役立ちます。',
    2: '持久力を高めるために、大切なことを考えてみましょう。階段登りやウォーキングなど日常生活で体を動かす機会を増やすことが大切です。ジョギングとウォーキングを交互に繰り返しながら３０分程度の継続を目標とし、慣れたらジョギングで３０分間の運動ができるようになるとよいでしょう。持久力は日常生活の疲労回復にも役立ちます。',
    3: '能力を向上させるために、トレーニングを考えてみましょう。体格の変化や発達する筋肉の性質も影響があり個人差も現れますが、個人の能力はトレーニングによって向上します。長時間走やインターバルトレーニングを実施し、能力の拡大を目指すとよいでしょう。',
    4: 'さらに能力を高めるために、トレーニングを考えてみましょう。高校期になると発達する筋繊維に個人差が現れますが、まずは長時間走やインターバルトレーニング、シャトルランなどを定期的に実施することで現状把握をするとよいでしょう。目標を明確に設定した継続的なトレーニングを実践することが大切です。',
    5: 'さらに能力を高めるために、トレーニングを考えてみましょう。高校期になると発達する筋繊維に個人差が現れますが、まずは長時間走やインターバルトレーニング、シャトルランなどを定期的に実施することで現状把握をするとよいでしょう。目標を明確に設定した継続的なトレーニングを実践することが大切です。',
  },
}

export const ShuttleRun = {
  computeValue: (shuttleRun: number) => {
    return Math.floor(shuttleRun)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = ShuttleRun.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = ShuttleRun.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = ShuttleRun.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = ShuttleRun.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = ShuttleRun.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = ShuttleRun.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = ShuttleRun.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = ShuttleRun.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = ShuttleRun.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = ShuttleRun.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [80, 1000] },
      9: { Range: [69, 79] },
      8: { Range: [57, 68] },
      7: { Range: [45, 56] },
      6: { Range: [33, 44] },
      5: { Range: [23, 32] },
      4: { Range: [15, 22] },
      3: { Range: [10, 14] },
      2: { Range: [8, 9] },
      1: { Range: [1, 7] },
    },
    FEMALE: {
      10: { Range: [64, 1000] },
      9: { Range: [54, 63] },
      8: { Range: [44, 53] },
      7: { Range: [35, 43] },
      6: { Range: [26, 34] },
      5: { Range: [19, 25] },
      4: { Range: [14, 18] },
      3: { Range: [10, 13] },
      2: { Range: [8, 9] },
      1: { Range: [1, 7] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [125, 1000] },
      9: { Range: [113, 124] },
      8: { Range: [102, 112] },
      7: { Range: [90, 101] },
      6: { Range: [76, 89] },
      5: { Range: [63, 75] },
      4: { Range: [51, 62] },
      3: { Range: [37, 50] },
      2: { Range: [26, 36] },
      1: { Range: [1, 25] },
    },
    FEMALE: {
      10: { Range: [88, 1000] },
      9: { Range: [76, 87] },
      8: { Range: [64, 75] },
      7: { Range: [54, 63] },
      6: { Range: [44, 53] },
      5: { Range: [35, 43] },
      4: { Range: [27, 34] },
      3: { Range: [21, 26] },
      2: { Range: [15, 20] },
      1: { Range: [1, 14] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [36, 1000] },
        4: { Range: [26, 35] },
        3: { Range: [15, 25] },
        2: { Range: [4, 14] },
        1: { Range: [0, 3] },
      },
      7: {
        5: { Range: [51, 1000] },
        4: { Range: [36, 50] },
        3: { Range: [22, 35] },
        2: { Range: [8, 21] },
        1: { Range: [0, 7] },
      },
      8: {
        5: { Range: [64, 1000] },
        4: { Range: [47, 63] },
        3: { Range: [29, 46] },
        2: { Range: [12, 28] },
        1: { Range: [0, 11] },
      },
      9: {
        5: { Range: [75, 1000] },
        4: { Range: [56, 74] },
        3: { Range: [36, 55] },
        2: { Range: [16, 35] },
        1: { Range: [0, 15] },
      },
      10: {
        5: { Range: [84, 1000] },
        4: { Range: [63, 83] },
        3: { Range: [42, 62] },
        2: { Range: [21, 41] },
        1: { Range: [0, 20] },
      },
      11: {
        5: { Range: [94, 1000] },
        4: { Range: [71, 93] },
        3: { Range: [49, 70] },
        2: { Range: [26, 48] },
        1: { Range: [0, 25] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [29, 1000] },
        4: { Range: [21, 28] },
        3: { Range: [13, 20] },
        2: { Range: [5, 12] },
        1: { Range: [0, 4] },
      },
      7: {
        5: { Range: [39, 1000] },
        4: { Range: [29, 38] },
        3: { Range: [18, 28] },
        2: { Range: [7, 17] },
        1: { Range: [0, 6] },
      },
      8: {
        5: { Range: [48, 1000] },
        4: { Range: [36, 47] },
        3: { Range: [23, 35] },
        2: { Range: [10, 22] },
        1: { Range: [0, 9] },
      },
      9: {
        5: { Range: [59, 1000] },
        4: { Range: [44, 58] },
        3: { Range: [28, 43] },
        2: { Range: [13, 27] },
        1: { Range: [0, 12] },
      },
      10: {
        5: { Range: [67, 1000] },
        4: { Range: [51, 66] },
        3: { Range: [34, 50] },
        2: { Range: [18, 33] },
        1: { Range: [0, 17] },
      },
      11: {
        5: { Range: [74, 1000] },
        4: { Range: [57, 73] },
        3: { Range: [39, 56] },
        2: { Range: [21, 38] },
        1: { Range: [0, 20] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [107, 1000] },
        4: { Range: [83, 106] },
        3: { Range: [59, 82] },
        2: { Range: [34, 58] },
        1: { Range: [0, 33] },
      },
      13: {
        5: { Range: [124, 1000] },
        4: { Range: [99, 123] },
        3: { Range: [74, 98] },
        2: { Range: [48, 73] },
        1: { Range: [0, 47] },
      },
      14: {
        5: { Range: [132, 1000] },
        4: { Range: [106, 131] },
        3: { Range: [81, 105] },
        2: { Range: [55, 80] },
        1: { Range: [0, 54] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [82, 1000] },
        4: { Range: [63, 81] },
        3: { Range: [44, 62] },
        2: { Range: [25, 43] },
        1: { Range: [0, 24] },
      },
      13: {
        5: { Range: [91, 1000] },
        4: { Range: [70, 90] },
        3: { Range: [49, 69] },
        2: { Range: [27, 48] },
        1: { Range: [0, 26] },
      },
      14: {
        5: { Range: [92, 1000] },
        4: { Range: [70, 91] },
        3: { Range: [49, 69] },
        2: { Range: [27, 48] },
        1: { Range: [0, 26] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [123, 1000] },
        4: { Range: [100, 122] },
        3: { Range: [77, 99] },
        2: { Range: [53, 76] },
        1: { Range: [0, 52] },
      },
      16: {
        5: { Range: [131, 1000] },
        4: { Range: [106, 130] },
        3: { Range: [80, 105] },
        2: { Range: [55, 79] },
        1: { Range: [0, 54] },
      },
      17: {
        5: { Range: [131, 1000] },
        4: { Range: [105, 130] },
        3: { Range: [80, 104] },
        2: { Range: [54, 79] },
        1: { Range: [0, 53] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [79, 1000] },
        4: { Range: [62, 78] },
        3: { Range: [45, 61] },
        2: { Range: [28, 44] },
        1: { Range: [0, 27] },
      },
      16: {
        5: { Range: [84, 1000] },
        4: { Range: [65, 83] },
        3: { Range: [46, 64] },
        2: { Range: [27, 45] },
        1: { Range: [0, 26] },
      },
      17: {
        5: { Range: [82, 1000] },
        4: { Range: [63, 81] },
        3: { Range: [44, 62] },
        2: { Range: [25, 43] },
        1: { Range: [0, 24] },
      },
    },
  },
  elementarySchoolComments: {
    5: '長い時間うんどうを続けて、中学生いこうにしんたいのはったつとともにはったつしていくじきゅうりょくをさらにのばしましょう。そのためには、スポーツでいえば、りくじょうきょうぎ・サッカー・バスケットボール、あそびでいえば鬼ごっこなどの鬼の数を増やし、時間を長くしておこなってみるとよいでしょう。',
    4: '長い時間うんどうを続けて、中学生いこうにしんたいのはったつとともにはったつしていくじきゅうりょくをさらにのばしましょう。そのためには、スポーツでいえば、りくじょうきょうぎ・サッカー・バスケットボール、あそびでいえば鬼ごっこなどの鬼の数を増やし、時間を長くしておこなってみるとよいでしょう。',
    3: 'サッカーやバスケットボールなどのスポーツで、長い時間からだを動かしてじきゅうりょくをのばしてみましょう。じきゅうりょくは中学生いこうにはったつしてきますが、今からあそびの中で鬼ごっこをしたり、にちじょう生活の中でたくさん走ってみることでこののうりょくをのばすことができます。',
    2: 'サッカーやバスケットボールなどのスポーツで、長い時間からだを動かしてじきゅうりょくをのばしてみましょう。じきゅうりょくは中学生いこうにはったつしてきますが、今からあそびの中で鬼ごっこをしたり、にちじょう生活の中でたくさん走ってみることでこののうりょくをのばすことができます。',
    1: 'サッカーやバスケットボールなどのスポーツで、長い時間からだを動かしてじきゅうりょくをのばしてみましょう。じきゅうりょくは中学生いこうにはったつしてきますが、今からあそびの中で鬼ごっこをしたり、にちじょう生活の中でたくさん走ってみることでこののうりょくをのばすことができます。',
  },
  middleSchoolComments: {
    5: '持久力をさらに伸ばすために、計画的なトレーニングについて考えてみましょう。長い距離をゆっくり長時間走ることや、スピードを上げたり下げたりしながら走るインターバルトレーニング等を行いながら定期的に記録を測定しましょう。目標を持って努力するとよいでしょう。',
    4: '持久力をさらに伸ばすために、計画的なトレーニングについて考えてみましょう。長い距離をゆっくり長時間走ることや、スピードを上げたり下げたりしながら走るインターバルトレーニング等を行いながら定期的に記録を測定しましょう。目標を持って努力するとよいでしょう。',
    3: '持久力を高めるために、トレーニングについて考えてみましょう。３０分程度のジョギングを目標とし、慣れてきたら途中で速度を上げ、きつくなったら止まらずにゆっくりしたジョギングに戻すといった繰り返し（インターバルトレーニング）を行うことも効果的です。体格の発達に合わせ、肺や毛細血管も発達させるとよいでしょう。',
    2: '持久力を伸ばすために、継続できることを心がけてみましょう。階段上りや徒歩など日常的に体を動かす機会を増やしましょう。まずは３０分程度の継続を目標とし、きつくなったら歩く、回復したら走るを繰り返し、慣れてきたら歩く距離を少なくしながら３０分のジョギングができるようになるとよいでしょう。',
    1: '持久力を伸ばすために、継続できることを心がけてみましょう。階段上りや徒歩など日常的に体を動かす機会を増やしましょう。まずは３０分程度の継続を目標とし、きつくなったら歩く、回復したら走るを繰り返し、慣れてきたら歩く距離を少なくしながら３０分のジョギングができるようになるとよいでしょう。',
  },
  highSchoolComments: {
    5: 'さらに能力を高めるために、トレーニングを考えてみましょう。高校期になると発達する筋繊維に個人差が現れますが、まずは長時間走やインターバルトレーニング、シャトルランなどを定期的に実施することで現状把握をするとよいでしょう。目標を明確に設定した継続的なトレーニングを実践することが大切です。',
    4: 'さらに能力を高めるために、トレーニングを考えてみましょう。高校期になると発達する筋繊維に個人差が現れますが、まずは長時間走やインターバルトレーニング、シャトルランなどを定期的に実施することで現状把握をするとよいでしょう。目標を明確に設定した継続的なトレーニングを実践することが大切です。',
    3: '能力を向上させるために、トレーニングを考えてみましょう。体格の変化や発達する筋肉の性質も影響があり個人差も現れますが、個人の能力はトレーニングによって向上します。長時間走やインターバルトレーニングを実施し、能力の拡大を目指すとよいでしょう。',
    2: '持久力を伸ばすために、継続できることを考えてみましょう。ジョギングとウォーキングを交互に繰り返しながら３０分程度の継続を目標とし、慣れたらジョギングで３０分間の運動ができるようになるとよいでしょう。階段登りやウォーキングなど、日常生活で体を動かす機会を増やすことが大切です。',
    1: '持久力を伸ばすために、継続できることを考えてみましょう。ジョギングとウォーキングを交互に繰り返しながら３０分程度の継続を目標とし、慣れたらジョギングで３０分間の運動ができるようになるとよいでしょう。階段登りやウォーキングなど、日常生活で体を動かす機会を増やすことが大切です。',
  },
}

export const SprintRun = {
  computeValue: (time: number) => {
    return Math.ceil(time * 10) / 10
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = SprintRun.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value < ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SprintRun.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value < ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = SprintRun.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value < ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SprintRun.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value < ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = SprintRun.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = SprintRun.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = SprintRun.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = SprintRun.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = SprintRun.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = SprintRun.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      1: { Range: [13.1, 1000] },
      2: { Range: [12.3, 13.1] },
      3: { Range: [11.5, 12.3] },
      4: { Range: [10.7, 11.5] },
      5: { Range: [10.0, 10.7] },
      6: { Range: [9.4, 10.0] },
      7: { Range: [8.9, 9.4] },
      8: { Range: [8.5, 8.9] },
      9: { Range: [8.1, 8.5] },
      10: { Range: [0.1, 8.1] },
    },
    FEMALE: {
      1: { Range: [13.3, 1000] },
      2: { Range: [12.5, 13.3] },
      3: { Range: [11.7, 12.5] },
      4: { Range: [11.0, 11.7] },
      5: { Range: [10.3, 11.0] },
      6: { Range: [9.7, 10.3] },
      7: { Range: [9.2, 9.7] },
      8: { Range: [8.8, 9.2] },
      9: { Range: [8.4, 8.8] },
      10: { Range: [0.1, 8.4] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      1: { Range: [9.8, 1000] },
      2: { Range: [9.1, 9.8] },
      3: { Range: [8.5, 9.1] },
      4: { Range: [8.0, 8.5] },
      5: { Range: [7.6, 8.0] },
      6: { Range: [7.3, 7.6] },
      7: { Range: [7.1, 7.3] },
      8: { Range: [6.9, 7.1] },
      9: { Range: [6.7, 6.9] },
      10: { Range: [0.1, 6.7] },
    },
    FEMALE: {
      1: { Range: [11.3, 1000] },
      2: { Range: [10.4, 11.3] },
      3: { Range: [9.9, 10.4] },
      4: { Range: [9.4, 9.9] },
      5: { Range: [9.0, 9.4] },
      6: { Range: [8.7, 9.0] },
      7: { Range: [8.4, 8.7] },
      8: { Range: [8.1, 8.4] },
      9: { Range: [7.8, 8.1] },
      10: { Range: [0.1, 7.8] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        1: { Range: [13.8, 1000] },
        2: { Range: [12.4, 13.7] },
        3: { Range: [11.0, 12.3] },
        4: { Range: [9.7, 10.9] },
        5: { Range: [0.1, 9.6] },
      },
      7: {
        1: { Range: [12.8, 1000] },
        2: { Range: [11.5, 12.7] },
        3: { Range: [10.2, 11.4] },
        4: { Range: [9.0, 10.1] },
        5: { Range: [0.1, 8.9] },
      },
      8: {
        1: { Range: [12.1, 1000] },
        2: { Range: [10.9, 12.0] },
        3: { Range: [9.7, 10.8] },
        4: { Range: [8.5, 9.6] },
        5: { Range: [0.1, 8.4] },
      },
      9: {
        1: { Range: [11.5, 1000] },
        2: { Range: [10.4, 11.4] },
        3: { Range: [9.3, 10.3] },
        4: { Range: [8.2, 9.2] },
        5: { Range: [0.1, 8.1] },
      },
      10: {
        1: { Range: [11.2, 1000] },
        2: { Range: [10.1, 11.1] },
        3: { Range: [8.9, 10.0] },
        4: { Range: [7.8, 8.8] },
        5: { Range: [0.1, 7.7] },
      },
      11: {
        1: { Range: [10.7, 1000] },
        2: { Range: [9.6, 10.6] },
        3: { Range: [8.6, 9.5] },
        4: { Range: [7.5, 8.5] },
        5: { Range: [0.1, 7.4] },
      },
    },
    FEMALE: {
      6: {
        1: { Range: [13.9, 1000] },
        2: { Range: [12.7, 13.8] },
        3: { Range: [11.5, 12.6] },
        4: { Range: [10.3, 11.4] },
        5: { Range: [0.1, 10.2] },
      },
      7: {
        1: { Range: [12.8, 1000] },
        2: { Range: [11.7, 12.7] },
        3: { Range: [10.7, 11.6] },
        4: { Range: [9.6, 10.6] },
        5: { Range: [0.1, 9.5] },
      },
      8: {
        1: { Range: [12.1, 1000] },
        2: { Range: [11.1, 12.0] },
        3: { Range: [10.1, 11.0] },
        4: { Range: [9.2, 10.0] },
        5: { Range: [0.1, 9.1] },
      },
      9: {
        1: { Range: [11.6, 1000] },
        2: { Range: [10.6, 11.5] },
        3: { Range: [9.7, 10.5] },
        4: { Range: [8.7, 9.6] },
        5: { Range: [0.1, 8.6] },
      },
      10: {
        1: { Range: [11.0, 1000] },
        2: { Range: [10.2, 10.9] },
        3: { Range: [9.3, 10.1] },
        4: { Range: [8.4, 9.2] },
        5: { Range: [0.1, 8.3] },
      },
      11: {
        1: { Range: [10.7, 1000] },
        2: { Range: [9.8, 10.6] },
        3: { Range: [8.9, 9.7] },
        4: { Range: [8.1, 8.8] },
        5: { Range: [0.1, 8.0] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        1: { Range: [10.2, 1000] },
        2: { Range: [9.1, 10.1] },
        3: { Range: [8.1, 9.0] },
        4: { Range: [7.1, 8.0] },
        5: { Range: [0.1, 7.0] },
      },
      13: {
        1: { Range: [9.4, 1000] },
        2: { Range: [8.5, 9.3] },
        3: { Range: [7.5, 8.4] },
        4: { Range: [6.6, 7.4] },
        5: { Range: [0.1, 6.5] },
      },
      14: {
        1: { Range: [8.9, 1000] },
        2: { Range: [8.0, 8.8] },
        3: { Range: [7.2, 7.9] },
        4: { Range: [6.3, 7.1] },
        5: { Range: [0.1, 6.2] },
      },
    },
    FEMALE: {
      12: {
        1: { Range: [10.5, 1000] },
        2: { Range: [9.6, 10.4] },
        3: { Range: [8.7, 9.5] },
        4: { Range: [7.8, 8.6] },
        5: { Range: [0.1, 7.7] },
      },
      13: {
        1: { Range: [10.2, 1000] },
        2: { Range: [9.3, 10.1] },
        3: { Range: [8.5, 9.2] },
        4: { Range: [7.5, 8.4] },
        5: { Range: [0.1, 7.4] },
      },
      14: {
        1: { Range: [10.2, 1000] },
        2: { Range: [9.2, 10.1] },
        3: { Range: [8.3, 9.1] },
        4: { Range: [7.4, 8.2] },
        5: { Range: [0.1, 7.3] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        1: { Range: [8.8, 1000] },
        2: { Range: [8.1, 8.7] },
        3: { Range: [7.3, 8.0] },
        4: { Range: [6.6, 7.2] },
        5: { Range: [0.1, 6.5] },
      },
      16: {
        1: { Range: [8.7, 1000] },
        2: { Range: [7.9, 8.6] },
        3: { Range: [7.1, 7.8] },
        4: { Range: [6.4, 7.0] },
        5: { Range: [0.1, 6.3] },
      },
      17: {
        1: { Range: [8.7, 1000] },
        2: { Range: [7.9, 8.6] },
        3: { Range: [7.0, 7.8] },
        4: { Range: [6.2, 6.9] },
        5: { Range: [0.1, 6.1] },
      },
    },
    FEMALE: {
      15: {
        1: { Range: [10.4, 1000] },
        2: { Range: [9.5, 10.3] },
        3: { Range: [8.7, 9.4] },
        4: { Range: [7.8, 8.6] },
        5: { Range: [0.1, 7.7] },
      },
      16: {
        1: { Range: [10.5, 1000] },
        2: { Range: [9.6, 10.4] },
        3: { Range: [8.6, 9.5] },
        4: { Range: [7.7, 8.5] },
        5: { Range: [0.1, 7.6] },
      },
      17: {
        1: { Range: [10.7, 1000] },
        2: { Range: [9.7, 10.6] },
        3: { Range: [8.6, 9.6] },
        4: { Range: [7.6, 8.5] },
        5: { Range: [0.1, 7.5] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'たんきょりそうには３つの大きな力が必要です。1つめはとまったじょうたいや方向へんかんしたじょうたいから、スピードをあげる「かそくりょく」、2つめはぜんりょくじのそくどである「トップスピード」、3つめはこうはんのきゅうげきなそくどげんしょうをふせぐ「スピードじきゅうりょく」です。このなかでにがてなものがあれば、リラックスしながらトレーニングして身につけていきましょう。うでのふりに気を付けて大きく走ってみるとよいでしょう。',
    4: 'たんきょりそうには３つの大きな力が必要です。1つめはとまったじょうたいや方向へんかんしたじょうたいから、スピードをあげる「かそくりょく」、2つめはぜんりょくじのそくどである「トップスピード」、3つめはこうはんのきゅうげきなそくどげんしょうをふせぐ「スピードじきゅうりょく」です。このなかでにがてなものがあれば、リラックスしながらトレーニングして身につけていきましょう。うでのふりに気を付けて大きく走ってみるとよいでしょう。',
    3: 'なわとびやうまとびなどのあそびでより大きなほはばをみにつけてみましょう。リラックスして、うでをふりながらあしをすばやく動かして走ってみるとあしのかいてんがよくなります。ほはばとあしのかいてんにちゅういしながら、走るスピードをのばしていけるとよいですね。',
    2: 'これから走るきかいを増やしていきましょう。りきむことなく、リラックスをしてあしやうでをなるべくすばやくうごかして走ってみよう。また、全力ではしることや、なわとびやうまとびなどのジャンプうんどうが大切です。からだをスムーズに動かしていくために走るどうさがあるスポーツをおこなうことがよいですね。',
    1: 'これから走るきかいを増やしていきましょう。りきむことなく、リラックスをしてあしやうでをなるべくすばやくうごかして走ってみよう。また、全力ではしることや、なわとびやうまとびなどのジャンプうんどうが大切です。からだをスムーズに動かしていくために走るどうさがあるスポーツをおこなうことがよいですね。',
  },
  middleSchoolComments: {
    5: '走速度をさらに高めるために、トレーニングの方法を考えてみましょう。走運動は、大きく３つの場面に分けられます。①加速（前傾姿勢）　②全力疾走（最高速度）　③最高速度維持（ピッチ向上）これらの場面別トレーニングや苦手な場面を意識したトレーニングを行うことが大切です。現在実施しているスポーツがあれば、その必要性に応じた場面を意識したトレーニングがよいでしょう。',
    4: '走速度をさらに高めるために、トレーニングの方法を考えてみましょう。走運動は、大きく３つの場面に分けられます。①加速（前傾姿勢）　②全力疾走（最高速度）　③最高速度維持（ピッチ向上）これらの場面別トレーニングや苦手な場面を意識したトレーニングを行うことが大切です。現在実施しているスポーツがあれば、その必要性に応じた場面を意識したトレーニングがよいでしょう。',
    3: '速く走るために、大切な意識について考えてみましょう。あまり力まず、手と足を上手く連動させてダイナミックに動かせるよう意識し、力まずまっすぐスムーズに腕を振りましょう。スタートは体を前傾させ、速度が速くなるにつれて体を起こすようにし、大きな走りができるように意識するのがよいでしょう。',
    2: '走力を高めるために、原因を考えてみましょう。身長の発育や筋力の発達が影響する事もありますが、脚の回転の速さや歩幅の大きさも影響があります。もちろん今後の成長にもよりますが、手足をよく動かしてダイナミックな動きを心がける事が大切です。身長が平均を上回っていれば脚力を高めることを少し意識するのがよいでしょう。',
    1: '走力を高めるために、原因を考えてみましょう。身長の発育や筋力の発達が影響する事もありますが、脚の回転の速さや歩幅の大きさも影響があります。もちろん今後の成長にもよりますが、手足をよく動かしてダイナミックな動きを心がける事が大切です。身長が平均を上回っていれば脚力を高めることを少し意識するのがよいでしょう。',
  },
  highSchoolComments: {
    5: 'すでに高い能力が見られますが、速度をさらに高め、維持するための意識を考えてみましょう。スタートの前傾からスムーズに立ち上がり、ピッチの向上とストライドの拡大により、速度を保つよう意識するとよいでしょう。走能力は、ピッチの向上とストライドの拡大によって向上します。',
    4: 'すでに高い能力が見られますが、速度をさらに高め、維持するための意識を考えてみましょう。スタートの前傾からスムーズに立ち上がり、ピッチの向上とストライドの拡大により、速度を保つよう意識するとよいでしょう。走能力は、ピッチの向上とストライドの拡大によって向上します。',
    3: '速度を高めるために、ピッチの向上とストライドの拡大を行うことが大切です。5段跳びやバウンディング等のジャンプトレーニングで脚のパワーを身につけたり、上半身と下半身の連動性を高めるとよいでしょう。リラックスして大きく速い腕振りを意識した登り坂走等も効果的です。',
    2: '走力を高めるために、原因を考えてみましょう。体幹や姿勢が影響することもありますが、脚のパワーや腕の振り方も影響があります。地面に着地している時間が長かったり、腕がまっすぐ速く触れなかったりしないように心がけましょう。まっすぐな姿勢でバウンディングを行うことや、膝をしっかりと上げる動き作り、自分の姿勢を確認しながら腕振りを行うのがよいでしょう。',
    1: '走力を高めるために、原因を考えてみましょう。体幹や姿勢が影響することもありますが、脚のパワーや腕の振り方も影響があります。地面に着地している時間が長かったり、腕がまっすぐ速く触れなかったりしないように心がけましょう。まっすぐな姿勢でバウンディングを行うことや、膝をしっかりと上げる動き作り、自分の姿勢を確認しながら腕振りを行うのがよいでしょう。',
  },
}

export const StandingJump = {
  computeValue: (standingJump1: number, standingJump2: number) => {
    let standingJump = Math.max(standingJump1, standingJump2)
    return Math.floor(standingJump)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = StandingJump.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = StandingJump.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = StandingJump.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = StandingJump.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = StandingJump.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = StandingJump.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = StandingJump.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = StandingJump.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = StandingJump.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = StandingJump.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [192, 1000] },
      9: { Range: [180, 191] },
      8: { Range: [168, 179] },
      7: { Range: [156, 167] },
      6: { Range: [143, 155] },
      5: { Range: [130, 142] },
      4: { Range: [117, 129] },
      3: { Range: [105, 116] },
      2: { Range: [93, 104] },
      1: { Range: [1, 92] },
    },
    FEMALE: {
      10: { Range: [181, 1000] },
      9: { Range: [170, 180] },
      8: { Range: [160, 169] },
      7: { Range: [147, 159] },
      6: { Range: [134, 146] },
      5: { Range: [121, 133] },
      4: { Range: [109, 120] },
      3: { Range: [98, 108] },
      2: { Range: [85, 97] },
      1: { Range: [1, 84] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [265, 1000] },
      9: { Range: [254, 264] },
      8: { Range: [242, 253] },
      7: { Range: [230, 241] },
      6: { Range: [218, 229] },
      5: { Range: [203, 217] },
      4: { Range: [188, 202] },
      3: { Range: [170, 187] },
      2: { Range: [150, 169] },
      1: { Range: [1, 149] },
    },
    FEMALE: {
      10: { Range: [210, 1000] },
      9: { Range: [200, 209] },
      8: { Range: [190, 199] },
      7: { Range: [179, 189] },
      6: { Range: [168, 178] },
      5: { Range: [157, 167] },
      4: { Range: [145, 156] },
      3: { Range: [132, 144] },
      2: { Range: [118, 131] },
      1: { Range: [1, 117] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [143, 1000] },
        4: { Range: [123, 142] },
        3: { Range: [104, 122] },
        2: { Range: [85, 103] },
        1: { Range: [0, 84] },
      },
      7: {
        5: { Range: [154, 1000] },
        4: { Range: [134, 153] },
        3: { Range: [114, 133] },
        2: { Range: [94, 113] },
        1: { Range: [0, 93] },
      },
      8: {
        5: { Range: [165, 1000] },
        4: { Range: [144, 164] },
        3: { Range: [124, 143] },
        2: { Range: [103, 123] },
        1: { Range: [0, 102] },
      },
      9: {
        5: { Range: [175, 1000] },
        4: { Range: [154, 174] },
        3: { Range: [133, 153] },
        2: { Range: [111, 132] },
        1: { Range: [0, 110] },
      },
      10: {
        5: { Range: [186, 1000] },
        4: { Range: [163, 185] },
        3: { Range: [140, 162] },
        2: { Range: [118, 139] },
        1: { Range: [0, 117] },
      },
      11: {
        5: { Range: [197, 1000] },
        4: { Range: [173, 196] },
        3: { Range: [150, 172] },
        2: { Range: [126, 149] },
        1: { Range: [0, 125] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [133, 1000] },
        4: { Range: [115, 132] },
        3: { Range: [97, 114] },
        2: { Range: [79, 96] },
        1: { Range: [0, 78] },
      },
      7: {
        5: { Range: [144, 1000] },
        4: { Range: [125, 143] },
        3: { Range: [107, 124] },
        2: { Range: [89, 106] },
        1: { Range: [0, 88] },
      },
      8: {
        5: { Range: [155, 1000] },
        4: { Range: [136, 154] },
        3: { Range: [116, 135] },
        2: { Range: [97, 115] },
        1: { Range: [0, 96] },
      },
      9: {
        5: { Range: [166, 1000] },
        4: { Range: [146, 165] },
        3: { Range: [126, 145] },
        2: { Range: [106, 125] },
        1: { Range: [0, 105] },
      },
      10: {
        5: { Range: [177, 1000] },
        4: { Range: [156, 176] },
        3: { Range: [145, 155] },
        2: { Range: [114, 134] },
        1: { Range: [0, 113] },
      },
      11: {
        5: { Range: [186, 1000] },
        4: { Range: [164, 185] },
        3: { Range: [142, 163] },
        2: { Range: [120, 141] },
        1: { Range: [0, 119] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [223, 1000] },
        4: { Range: [196, 222] },
        3: { Range: [169, 195] },
        2: { Range: [142, 168] },
        1: { Range: [0, 141] },
      },
      13: {
        5: { Range: [242, 1000] },
        4: { Range: [214, 241] },
        3: { Range: [185, 213] },
        2: { Range: [156, 184] },
        1: { Range: [0, 155] },
      },
      14: {
        5: { Range: [255, 1000] },
        4: { Range: [228, 254] },
        3: { Range: [200, 227] },
        2: { Range: [172, 199] },
        1: { Range: [0, 171] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [204, 1000] },
        4: { Range: [180, 203] },
        3: { Range: [156, 179] },
        2: { Range: [131, 155] },
        1: { Range: [0, 130] },
      },
      13: {
        5: { Range: [210, 1000] },
        4: { Range: [185, 209] },
        3: { Range: [159, 184] },
        2: { Range: [134, 158] },
        1: { Range: [0, 133] },
      },
      14: {
        5: { Range: [213, 1000] },
        4: { Range: [188, 212] },
        3: { Range: [163, 187] },
        2: { Range: [137, 162] },
        1: { Range: [0, 136] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [253, 1000] },
        4: { Range: [227, 252] },
        3: { Range: [202, 226] },
        2: { Range: [176, 201] },
        1: { Range: [0, 175] },
      },
      16: {
        5: { Range: [259, 1000] },
        4: { Range: [234, 258] },
        3: { Range: [208, 233] },
        2: { Range: [182, 207] },
        1: { Range: [0, 181] },
      },
      17: {
        5: { Range: [265, 1000] },
        4: { Range: [238, 264] },
        3: { Range: [210, 237] },
        2: { Range: [183, 209] },
        1: { Range: [0, 182] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [203, 1000] },
        4: { Range: [181, 202] },
        3: { Range: [158, 180] },
        2: { Range: [135, 157] },
        1: { Range: [0, 134] },
      },
      16: {
        5: { Range: [206, 1000] },
        4: { Range: [182, 205] },
        3: { Range: [158, 181] },
        2: { Range: [134, 157] },
        1: { Range: [0, 133] },
      },
      17: {
        5: { Range: [207, 1000] },
        4: { Range: [182, 206] },
        3: { Range: [157, 181] },
        2: { Range: [132, 156] },
        1: { Range: [0, 131] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'ひごろから手足を使った大きな動きを心がけて、からだのうまい使い方を身につけましょう。上半身・下半身の使い方や、下半身を中心としたきんりょくのはったつによりたちはばとびはこうじょうします。今のうちから上半身と下半身のうまい使い方やはやい動きをせっきょくてきにおこなってみるとよいですね。',
    4: 'ひごろから手足を使った大きな動きを心がけて、からだのうまい使い方を身につけましょう。上半身・下半身の使い方や、下半身を中心としたきんりょくのはったつによりたちはばとびはこうじょうします。今のうちから上半身と下半身のうまい使い方やはやい動きをせっきょくてきにおこなってみるとよいですね。',
    3: 'ひごろからリラックスして、タイミングよくジャンプができるように心がけてみましょう。たとえば5ほでどこまでとべるかをきそうなどのジャンプをいしきしたり、はやい動きや上半身・下半身のうまい使い方をおぼえたりすることで、ちょうやくにかかわるきんりょくの土台を作ることができます。このきんりょくは高校生ぐらいからきゅうげきにはったつしますので、今から準備をしていきましょう。',
    2: 'ひごろからジャンプをつかった動きをいしきしてちょうやくりょくを高めてみましょう。そのためにはひざ・足くび・またのかんせつの力や、かくかんせつのうまい使い方・上半身と下半身のうまい使い方などがじゅうようです。なわとび、高いいちのはっぱにふれるなどのジャンプの動きや、バスケットボールを足元から高くほうりなげたりしてかんせつのうまい使い方を身につけましょう。',
    1: 'ひごろからジャンプをつかった動きをいしきしてちょうやくりょくを高めてみましょう。そのためにはひざ・足くび・またのかんせつの力や、かくかんせつのうまい使い方・上半身と下半身のうまい使い方などがじゅうようです。なわとび、高いいちのはっぱにふれるなどのジャンプの動きや、バスケットボールを足元から高くほうりなげたりしてかんせつのうまい使い方を身につけましょう。',
  },
  middleSchoolComments: {
    5: 'すでに高い能力が見られますので、トレーニングについて考えてみましょう。上半身を上手く使ったバウンディング（片足ずつ交互の連続ジャンプ）やホッピング（片足で行う連続ジャンプ）、低い姿勢からの連続した垂直跳びなどを行うのがよいでしょう。脚パワーの向上や脚の伸展と腕の振込みのタイミングが大切です。',
    4: 'すでに高い能力が見られますので、トレーニングについて考えてみましょう。上半身を上手く使ったバウンディング（片足ずつ交互の連続ジャンプ）やホッピング（片足で行う連続ジャンプ）、低い姿勢からの連続した垂直跳び等を行うのがよいでしょう。脚パワーの向上や脚の伸展と腕の振込みのタイミングが大切です。',
    3: '記録をさらに向上させるために、タイミングについて考えてみましょう。脚の屈伸と腕の振り込みのタイミングを一致させながら、前方へ飛び出す意識が大切です。腕の振りは大きくダイナミックに行っていきましょう。まずは垂直跳びでより高く飛べるよう意識したジャンプを行うのがよいでしょう。',
    2: '跳躍力を高めるために、重要なことを考えてみましょう。股関節→膝→足首を伸展させるタイミングや上半身と下半身の動作のタイミングを合わせることが大切です。日ごろから運動をする機会の少ない人は、しっかり走ることや垂直跳びのようなジャンプをダイナミックに行うことを心がけるのがよいでしょう。',
    1: '跳躍力を高めるために、重要なことを考えてみましょう。股関節→膝→足首を伸展させるタイミングや上半身と下半身の動作のタイミングを合わせることが大切です。日ごろから運動をする機会の少ない人は、しっかり走ることや垂直跳びのようなジャンプをダイナミックに行うことを心がけるのがよいでしょう。',
  },
  highSchoolComments: {
    5: 'すでに高い能力が見られますが、瞬発力を高めるためにタイミングよくダイナミックな動きができるようトレーニングしましょう。垂直跳びやバウンディングなどによる動作を改善し、高校期に発達する筋力を高めるとよいでしょう。股関節・背筋力・脚筋力、それぞれの伸展を助ける腕の振り込み動作のタイミングやスピードが大切です。',
    4: 'すでに高い能力が見られますが、瞬発力を高めるためにタイミングよくダイナミックな動きができるようトレーニングしましょう。垂直跳びやバウンディングなどによる動作を改善し、高校期に発達する筋力を高めるとよいでしょう。股関節・背筋力・脚筋力、それぞれの伸展を助ける腕の振り込み動作のタイミングやスピードが大切です。',
    3: '練習について考えてみましょう。力を瞬間的・爆発的に使うために、より単純な垂直跳びで、より高く飛べるように意識した練習を行うことが大切です。膝関節の角度が小さくならないよう注意しながら屈曲から伸展に変わる時間を短くし、腕の振り込みはタイミングを合わせて大きく行うとよいでしょう。',
    2: '立ち幅跳びに重要なことを考えてみましょう。股→膝→足関節をタイミングよく速く伸展させる筋力と脚伸展に一致した腕の振り込みが大切です。筋力の発達やタイミングが影響することもありますが、垂直跳びなどで動作のタイミングを合わせながら方向を少しずつ上方から前方へ変化させるように練習するのがよいでしょう。',
    1: '立ち幅跳びに重要なことを考えてみましょう。股→膝→足関節をタイミングよく速く伸展させる筋力と脚伸展に一致した腕の振り込みが大切です。筋力の発達やタイミングが影響することもありますが、垂直跳びなどで動作のタイミングを合わせながら方向を少しずつ上方から前方へ変化させるように練習するのがよいでしょう。',
  },
}

export const HandballThrow = {
  computeValue: (handballThrow1: number, handballThrow2: number) => {
    let handballThrow = Math.max(handballThrow1, handballThrow2)
    return Math.floor(handballThrow)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = HandballThrow.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = HandballThrow.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = HandballThrow.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = HandballThrow.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = HandballThrow.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = HandballThrow.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = HandballThrow.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = HandballThrow.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = HandballThrow.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = HandballThrow.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [40, 1000] },
      9: { Range: [35, 39] },
      8: { Range: [30, 34] },
      7: { Range: [24, 29] },
      6: { Range: [18, 23] },
      5: { Range: [13, 17] },
      4: { Range: [10, 12] },
      3: { Range: [7, 9] },
      2: { Range: [5, 6] },
      1: { Range: [1, 4] },
    },
    FEMALE: {
      10: { Range: [25, 1000] },
      9: { Range: [21, 24] },
      8: { Range: [17, 20] },
      7: { Range: [14, 16] },
      6: { Range: [11, 13] },
      5: { Range: [8, 10] },
      4: { Range: [6, 7] },
      3: { Range: [5, 5.9] },
      2: { Range: [4, 4.9] },
      1: { Range: [1, 3] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [37, 1000] },
      9: { Range: [34, 36] },
      8: { Range: [31, 33] },
      7: { Range: [28, 30] },
      6: { Range: [25, 27] },
      5: { Range: [22, 24] },
      4: { Range: [19, 21] },
      3: { Range: [16, 18] },
      2: { Range: [13, 15] },
      1: { Range: [1, 12] },
    },
    FEMALE: {
      10: { Range: [23, 1000] },
      9: { Range: [20, 22] },
      8: { Range: [18, 19] },
      7: { Range: [16, 17] },
      6: { Range: [14, 15] },
      5: { Range: [12, 13] },
      4: { Range: [11, 11.9] },
      3: { Range: [10, 10.9] },
      2: { Range: [8, 9] },
      1: { Range: [1, 7] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [14, 1000] },
        4: { Range: [11, 13] },
        3: { Range: [7, 10] },
        2: { Range: [4, 6] },
        1: { Range: [0, 3] },
      },
      7: {
        5: { Range: [20, 1000] },
        4: { Range: [15, 19] },
        3: { Range: [10, 14] },
        2: { Range: [5, 9] },
        1: { Range: [0, 4] },
      },
      8: {
        5: { Range: [25, 1000] },
        4: { Range: [19, 24] },
        3: { Range: [13, 18] },
        2: { Range: [7, 12] },
        1: { Range: [0, 6] },
      },
      9: {
        5: { Range: [31, 1000] },
        4: { Range: [23, 30] },
        3: { Range: [16, 22] },
        2: { Range: [8, 15] },
        1: { Range: [0, 7] },
      },
      10: {
        5: { Range: [36, 1000] },
        4: { Range: [28, 35] },
        3: { Range: [19, 27] },
        2: { Range: [10, 18] },
        1: { Range: [0, 9] },
      },
      11: {
        5: { Range: [42, 1000] },
        4: { Range: [32, 41] },
        3: { Range: [22, 31] },
        2: { Range: [13, 21] },
        1: { Range: [0, 12] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [10, 1000] },
        4: { Range: [7, 9] },
        3: { Range: [5, 6] },
        2: { Range: [3, 4] },
        1: { Range: [0, 2] },
      },
      7: {
        5: { Range: [12, 1000] },
        4: { Range: [9, 11] },
        3: { Range: [7, 8] },
        2: { Range: [4, 6] },
        1: { Range: [0, 3] },
      },
      8: {
        5: { Range: [15, 1000] },
        4: { Range: [12, 14] },
        3: { Range: [8, 11] },
        2: { Range: [5, 7] },
        1: { Range: [0, 4] },
      },
      9: {
        5: { Range: [19, 1000] },
        4: { Range: [14, 18] },
        3: { Range: [10, 13] },
        2: { Range: [6, 9] },
        1: { Range: [0, 5] },
      },
      10: {
        5: { Range: [22, 1000] },
        4: { Range: [17, 21] },
        3: { Range: [12, 16] },
        2: { Range: [7, 11] },
        1: { Range: [0, 6] },
      },
      11: {
        5: { Range: [25, 1000] },
        4: { Range: [19, 24] },
        3: { Range: [14, 18] },
        2: { Range: [8, 13] },
        1: { Range: [0, 7] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [26, 1000] },
        4: { Range: [21, 25] },
        3: { Range: [16, 20] },
        2: { Range: [11, 15] },
        1: { Range: [0, 10] },
      },
      13: {
        5: { Range: [30, 1000] },
        4: { Range: [24, 29] },
        3: { Range: [18, 23] },
        2: { Range: [13, 17] },
        1: { Range: [0, 12] },
      },
      14: {
        5: { Range: [33, 1000] },
        4: { Range: [27, 32] },
        3: { Range: [21, 26] },
        2: { Range: [15, 20] },
        1: { Range: [0, 14] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [18, 1000] },
        4: { Range: [14, 17] },
        3: { Range: [10, 13] },
        2: { Range: [6, 9] },
        1: { Range: [0, 5] },
      },
      13: {
        5: { Range: [20, 1000] },
        4: { Range: [15, 19] },
        3: { Range: [11, 14] },
        2: { Range: [7, 10] },
        1: { Range: [0, 6] },
      },
      14: {
        5: { Range: [21, 1000] },
        4: { Range: [16, 20] },
        3: { Range: [12, 15] },
        2: { Range: [8, 11] },
        1: { Range: [0, 7] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [33, 1000] },
        4: { Range: [27, 32] },
        3: { Range: [21, 26] },
        2: { Range: [15, 20] },
        1: { Range: [0, 14] },
      },
      16: {
        5: { Range: [35, 1000] },
        4: { Range: [29, 34] },
        3: { Range: [22, 28] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
      17: {
        5: { Range: [35, 1000] },
        4: { Range: [29, 34] },
        3: { Range: [22, 28] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [20, 1000] },
        4: { Range: [16, 19] },
        3: { Range: [12, 15] },
        2: { Range: [7, 11] },
        1: { Range: [0, 6] },
      },
      16: {
        5: { Range: [21, 1000] },
        4: { Range: [16, 20] },
        3: { Range: [12, 15] },
        2: { Range: [8, 11] },
        1: { Range: [0, 7] },
      },
      17: {
        5: { Range: [21, 1000] },
        4: { Range: [17, 20] },
        3: { Range: [12, 16] },
        2: { Range: [8, 11] },
        1: { Range: [0, 7] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'いろいろなボールを使い、スピード・ひきょり・コントロール・うまいパスができるようにちょうせんしてみましょう。スポーツによっては、ボールをなげるのうりょくだけでなく、大きなボールをなげることや、じょうきょうをみてパスやボールのコントロールができることもじゅうようです。これからもなげる・コントロールすることをいしきしながらスポーツやあそびをおこなってみましょう。',
    4: 'いろいろなボールを使い、スピード・ひきょり・コントロール・うまいパスができるようにちょうせんしてみましょう。スポーツによっては、ボールをなげるのうりょくだけでなく、大きなボールをなげることや、じょうきょうをみてパスやボールのコントロールができることもじゅうようです。これからもなげる・コントロールすることをいしきしながらスポーツやあそびをおこなってみましょう。',
    3: '足のふみ出だしからゆびさきからボールがはなれるまでリラックスしながらうでをじゅうなんに使い、なげるのうりょくを高めましょう。「強く」ではなく「ゆっくり大きく」なげるいしきをすることが大切です。そのときに、下半身をふみ出したときの力をたいかん・かた・ひじ・手・ゆびのじゅんにうまくつたえていくように心がけましょう。',
    2: 'やきゅうやソフトボール、ドッジボールなどをおこないながら、「なげる」どうさや経験をたくさんしてみましょう。かみでっぽうをあたまの上でうまくならすれんしゅうもよいでしょう。リラックスしながら大きく足をふみだし、なげる手と反対の手を前から自分にひきつけるどうさを取りいれてみましょう。',
    1: 'やきゅうやソフトボール、ドッジボールなどをおこないながら、「なげる」どうさや経験をたくさんしてみましょう。かみでっぽうをあたまの上でうまくならすれんしゅうもよいでしょう。リラックスしながら大きく足をふみだし、なげる手と反対の手を前から自分にひきつけるどうさを取りいれてみましょう。',
  },
  middleSchoolComments: {
    5: '高い能力が見られますので、実践的なトレーニングを考えてみましょう。より遠くへ、より速くといった単純な投球だけでなく、ステップを入れたりキャッチしてから素早く正確に投げたり等、速度や距離だけでなく、パスやコントロールも大切です。ボールの回転は、方向や距離に影響するので指先の感覚に注意して投げるのがよいでしょう。',
    4: '高い能力が見られますので、実践的なトレーニングを考えてみましょう。より遠くへ、より速くといった単純な投球だけでなく、ステップを入れたりキャッチしてから素早く正確に投げたり等、速度や距離だけでなく、パスやコントロールも大切です。ボールの回転は、方向や距離に影響するので指先の感覚に注意して投げるのがよいでしょう。',
    3: '投げる能力をさらに高めるには、力に頼らず上半身をリラックスさせて、大きなフォームを意識して投球しましょう。下半身の踏み出しから発生した力を、体幹→肩→手首→指の順に上手く伝えていくとよいでしょう。バランス力を高め、足を踏み出す方向性などにも注意することが大切です。',
    2: '遠投するためには筋力も必要となります。ボールが重たく握れないこともありますが、より軽いボールを使用し、遠投ではなく、まずは壁に向かって球速を意識して投球してみましょう。肘が下がらないように大きなフォームやボールに力を伝える感覚を知ることが大切です。正確な動きのイメージと動作の習得を心がけましょう。',
    1: '遠投するためには筋力も必要となります。ボールが重たく握れないこともありますが、より軽いボールを使用し、遠投ではなく、まずは壁に向かって球速を意識して投球してみましょう。肘が下がらないように大きなフォームやボールに力を伝える感覚を知ることが大切です。正確な動きのイメージと動作の習得を心がけましょう。',
  },
  highSchoolComments: {
    5: 'さらに高いパフォーマンスを発揮するために、トレーニングを考えてみましょう。ステップを入れたり、キャッチしてから素早く正確に投げる等の実践的なトレーニングを実施するのがよいでしょう。様々な距離や速度で投げ分ける能力が必要になるため、投げる速度や距離だけでなくコントロールも大切です。',
    4: 'さらに高いパフォーマンスを発揮するために、トレーニングを考えてみましょう。ステップを入れたり、キャッチしてから素早く正確に投げる等の実践的なトレーニングを実施するのがよいでしょう。様々な距離や速度で投げ分ける能力が必要になるため、投げる速度や距離だけでなくコントロールも大切です。',
    3: '投げる能力をさらに高めるために、練習を考えてみましょう。ステップ動作から勢いよく脚を踏み出し、肘を下げずにダイナミックなフォームで投球できるようにするとよいでしょう。下半身の踏み出しにより得られる力を向上させることと、その力を上手くボールに伝える技術が大切です。',
    2: '遠投するためには筋力も必要になります。ボールが重く握れないこともありますが、まずは軽いボールや握れるボールを使用し、ステップから踏み出した勢いを上手く腕に伝え、肘を下げずに大きな投動作ができるように練習するとよいでしょう。正確な動きのイメージと動作の習得が大切です。',
    1: '遠投するためには筋力も必要になります。ボールが重く握れないこともありますが、まずは軽いボールや握れるボールを使用し、ステップから踏み出した勢いを上手く腕に伝え、肘を下げずに大きな投動作ができるように練習するとよいでしょう。正確な動きのイメージと動作の習得が大切です。',
  },
}

export const FitnessTest = {
  isComplete: (result) => {
    let count = 0
    if (result.questionnaire || result.questionnaireV2) count += 1
    if (result.grip?.value > 0) count += 1
    if (result.sitUps?.value > 0) count += 1
    if (result.bending?.value > 0) count += 1
    if (result.sideJump?.value > 0) count += 1
    if (result.shuttleRun?.value > 0) count += 1
    if (result.sprintRun?.value > 0) count += 1
    if (result.standingJump?.value > 0) count += 1
    if (result.handballThrow?.value > 0) count += 1
    if (result.enduranceRun?.value > 0) count += 1

    return count >= 9
  },
  computePoints: (result) => {
    let grip = result?.grip?.points ?? 0
    let bending = result?.bending?.points ?? 0
    let handballThrow = result?.handballThrow?.points ?? 0
    let shuttleRun = result?.shuttleRun?.points
    let sideJump = result?.sideJump?.points ?? 0
    let sitUps = result?.sitUps?.points ?? 0
    let sprintRun = result?.sprintRun?.points ?? 0
    let standingJump = result?.standingJump?.points ?? 0
    let enduranceRun = result?.enduranceRun?.points

    if (!shuttleRun && !enduranceRun) {
      return (
        grip +
        bending +
        handballThrow +
        sideJump +
        sitUps +
        sprintRun +
        standingJump
      )
    }

    if (!shuttleRun) {
      return (
        grip +
        bending +
        handballThrow +
        sideJump +
        sitUps +
        sprintRun +
        standingJump +
        enduranceRun
      )
    }

    return (
      grip +
      bending +
      handballThrow +
      sideJump +
      sitUps +
      sprintRun +
      standingJump +
      shuttleRun
    )
  },
  computeGrade: (points: number, age: number) => {
    let grade = 'E'

    if (age < 6) {
      age = 6
    } else if (age > 19) {
      age = 19
    }
    let ranges = FitnessTest.ranges[age]

    for (let key in ranges) {
      if (points >= ranges[key].Range[0] && points <= ranges[key].Range[1]) {
        grade = key
      }
    }

    return grade
  },
  ranges: {
    6: {
      A: { Range: [39, 100] },
      B: { Range: [33, 38] },
      C: { Range: [27, 32] },
      D: { Range: [22, 26] },
      E: { Range: [0, 21] },
    },
    7: {
      A: { Range: [47, 100] },
      B: { Range: [41, 46] },
      C: { Range: [34, 40] },
      D: { Range: [27, 33] },
      E: { Range: [0, 26] },
    },
    8: {
      A: { Range: [53, 100] },
      B: { Range: [46, 52] },
      C: { Range: [39, 45] },
      D: { Range: [32, 38] },
      E: { Range: [0, 31] },
    },
    9: {
      A: { Range: [59, 100] },
      B: { Range: [52, 58] },
      C: { Range: [45, 51] },
      D: { Range: [38, 44] },
      E: { Range: [0, 37] },
    },
    10: {
      A: { Range: [65, 100] },
      B: { Range: [58, 64] },
      C: { Range: [50, 57] },
      D: { Range: [42, 49] },
      E: { Range: [0, 41] },
    },
    11: {
      A: { Range: [71, 100] },
      B: { Range: [63, 70] },
      C: { Range: [55, 62] },
      D: { Range: [46, 54] },
      E: { Range: [0, 45] },
    },
    12: {
      A: { Range: [51, 100] },
      B: { Range: [41, 50] },
      C: { Range: [32, 40] },
      D: { Range: [22, 31] },
      E: { Range: [0, 21] },
    },
    13: {
      A: { Range: [57, 100] },
      B: { Range: [47, 56] },
      C: { Range: [37, 46] },
      D: { Range: [27, 36] },
      E: { Range: [0, 26] },
    },
    14: {
      A: { Range: [60, 100] },
      B: { Range: [51, 59] },
      C: { Range: [41, 50] },
      D: { Range: [31, 40] },
      E: { Range: [0, 30] },
    },
    15: {
      A: { Range: [61, 100] },
      B: { Range: [52, 60] },
      C: { Range: [41, 51] },
      D: { Range: [31, 40] },
      E: { Range: [0, 30] },
    },
    16: {
      A: { Range: [63, 100] },
      B: { Range: [53, 62] },
      C: { Range: [42, 52] },
      D: { Range: [31, 41] },
      E: { Range: [0, 30] },
    },
    17: {
      A: { Range: [65, 100] },
      B: { Range: [54, 64] },
      C: { Range: [43, 53] },
      D: { Range: [31, 42] },
      E: { Range: [0, 30] },
    },
    18: {
      A: { Range: [65, 100] },
      B: { Range: [54, 64] },
      C: { Range: [43, 53] },
      D: { Range: [31, 42] },
      E: { Range: [0, 30] },
    },
    19: {
      A: { Range: [65, 100] },
      B: { Range: [54, 64] },
      C: { Range: [43, 53] },
      D: { Range: [31, 42] },
      E: { Range: [0, 30] },
    },
  },
}
