import { Anchor } from '@sergeimeza/uikit-react'
import axios from 'axios'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'
import { utils, writeFile } from 'xlsx'
import Fitness from '../../assets/images/fitness.png'
import { titles } from '../../utils/titles'
import { TochigiLayout } from '../layout/TochigiLayout'

let metadata_sample = {
  year: 2021,
  city: '宇都宮市',
  schoolName: '宇都宮市立中央小学校',
  totalStudentsCount: 120,
  studentCount: 2,
  participation_percentage: '25.83',
  ab_de: 1,
  s_rank_count: 1,
  organizationName: 'Appcrunch LTD',
  uploaderName: 'foo',
  uploaderContactEmail: 'sergei@lpp.pw',
  uploaderContactPhone: '+818090999882',
  fileId: 'a2b29cb2-7d2e-48af-a1f2-118b48845d16',
  other: '',
}

// const resultHeaderColumns = {
//   'コード': 'schoolCode',
//   '学校名': 'schoolName',
//   '名前': 'name',
//   '学年': 'grade',
//   '組': 'class',
//   '出席番号': 'attendanceNumber',
//   '性別': 'gender',
//   '身長': 'height',
//   '体重': 'weight',
//   '握力': 'grip',
//   '上体起': 'sitUps',
//   '体前屈': 'bending',
//   '反復横': 'sideJump',
//   'シャトルラン': 'shuttleRun',
//   '50m走': 'sprintRun',
//   '立ち幅': 'standingJump',
//   'ボール投': 'handballThrow',
//   '持久走': 'enduranceRun',
//   'Q1': 'q1',
//   'Q2': 'q2',
//   'Q3': 'q3',
//   'Q4': 'q4',
//   'Q5': 'q5',
//   'Q6': 'q6',
//   'Q7': 'q7',
//   'Q8': 'q8',
//   '得点_握力': 'gripPoint',
//   '得点_上体起こし': 'sitUpsPoint',
//   '得点_長座体前屈': 'bendingPoint',
//   '得点_反復横とび': 'sideJumpPoint',
//   '得点_シャトルラン': 'shuttleRunPoint',
//   '得点_50m走': 'sprintRunPoint',
//   '得点_立ち幅とび': 'standingJumpPoint',
//   '得点_ボール投げ': 'handballThrowPoint',
//   '得点_持久走': 'enduranceRunPoint',
//   '合計得点': 'totalPoints',
//   '段階': 'rank'
// }

// const templateFileForCode = (code: string) => {
//   if (code === 'B1') {
//     return '/R5tochigi_template_es.xlsx'
//   } else if (code === 'C1') {
//     return '/R5tochigi_template_jh.xlsx'
//   } else if (code === 'C2') {
//     return '/R5tochigi_template_jh.xlsx'
//   } else if (code === 'D1') {
//     return '/R5tochigi_template_hs.xlsx'
//   } else if (code === 'D2') {
//     return '/R5tochigi_template_hs.xlsx'
//   }
//   return `/R5tochigi_template_es.xlsx`
// }

const SchoolUploadPage = () => {
  const [metadata, setMetadata] = useState<any[]>()
  const year = 2024
  const [schoolCategory, setSchoolCategory] = useState('')
  const [loading, setLoading] = useState(false)

  function transformMetaData(data: any[]) {
    return data.map((item) => {
      return Object.keys(metadata_sample).reduce(
        (acc, curr) => ({ ...acc, [titles[curr]]: item[curr] }),
        {},
      )
    })
  }

  useEffect(() => {
    axios
      .create({ baseURL: 'https://api-v2.alpha-pestalozzi.com' })
      .get(`/tochigi/upload-metadata/?year=${year}`)
      .then((res) => {
        // results
        setMetadata(transformMetaData(res.data))
      })
      .catch(() => {})
  }, [year])

  function handleDownloadAllMetadata() {
    if (!metadata) return

    let workbook = utils.book_new()
    let worksheet = utils.json_to_sheet(metadata, {})
    utils.book_append_sheet(workbook, worksheet, 'metadata')
    writeFile(workbook, 'アップロード情報（すべての学校単位）.xlsx')
  }

  async function handleDownloadAllResults(schoolCategoryCode) {
    // .get(`/tochigi/uploaded-results/?year=${year}`)
    setLoading(true)
    setSchoolCategory(schoolCategoryCode)
    let results = []
    try {
      const resp = await axios.get(
        `https://api-v2.alpha-pestalozzi.com/tochigi/uploaded-results/?year=${year}&schoolCategoryCode=${schoolCategoryCode}`,
      )
      results = resp.data.data
    } catch (err: unknown) {
      console.error('/tochigi/uploaded-results/ error:', err)
      setLoading(false)
      return
    }

    fetch(
      schoolCategoryCode === 'B1'
        ? '/templates/R5tochigi_template_es.xlsx'
        : schoolCategoryCode === 'C1'
        ? '/templates/R5tochigi_template_jh.xlsx'
        : '/templates/R5tochigi_template_hs.xlsx',
    )
      .then((response) => response.arrayBuffer())
      .then(async (buffer) => {
        const workbook = new ExcelJS.Workbook()
        await workbook.xlsx.load(buffer)
        const worksheet = workbook.worksheets[0]
        const compareString = (a: any, b: any) => {
          if (a === undefined) return -1
          if (b === undefined) return 1
          return a.localeCompare(b)
        }
        results.sort((a, b) => {
          if (a.city !== b.city) return compareString(a.city, b.city)
          if (a.schoolName !== b.schoolName)
            return compareString(a.schoolName, b.schoolName)
          if (a.grade !== b.grade) return Number(a.grade) - Number(b.grade)
          if (a.class !== b.class) return Number(a.class) - Number(b.class)
          if (a.attendance_number !== b.attendance_number)
            return Number(a.attendance_number) - Number(b.attendance_number)
          return Number(a.gender) - Number(b.gender)
        })
        for (const [index, result] of results.entries()) {
          const lastRow = worksheet.getRow(index + 5)
          let id = 1
          lastRow.getCell(id++).value = index + 1
          lastRow.getCell(id++).value = result.schoolName
          lastRow.getCell(id++).value = result.grade ?? 0
          lastRow.getCell(id++).value = result.class ?? 0
          lastRow.getCell(id++).value = result.attendance_number ?? 0
          lastRow.getCell(id++).value = result.gender
          lastRow.getCell(id++).value = result.family_name
          lastRow.getCell(id++).value = result.given_name
          lastRow.getCell(id++).value = result.family_name_hiragana
          lastRow.getCell(id++).value = result.given_name_hiragana
          lastRow.getCell(id++).value = result.birth_year
          lastRow.getCell(id++).value = result.birth_month
          lastRow.getCell(id++).value = result.birth_day
          lastRow.getCell(id++).value = result.height
          lastRow.getCell(id++).value = result.weight
          lastRow.getCell(id++).value = result.grip
          lastRow.getCell(id++).value = result.sit_ups
          lastRow.getCell(id++).value = result.bending
          lastRow.getCell(id++).value = result.side_jump
          lastRow.getCell(id++).value = result.shuttle_run
          lastRow.getCell(id++).value = result.sprint_run
          lastRow.getCell(id++).value = result.standing_jump
          lastRow.getCell(id++).value = result.ball_throw
          lastRow.getCell(id++).value = result.total_points
          lastRow.getCell(id++).value = result.rank
          if (schoolCategoryCode === 'B1')
            lastRow.getCell(id++).value = result.q4
          lastRow.getCell(id++).value = result.q5
          lastRow.getCell(id++).value = result.q6
          lastRow.getCell(id++).value = result.q7
          lastRow.getCell(id++).value = result.q8
          lastRow.getCell(id++).value = result.q9
          lastRow.getCell(id++).value = result.q10
          lastRow.getCell(id++).value = result.q11
          if (schoolCategoryCode !== 'B1')
            lastRow.getCell(id++).value = result.q12
        }
        setLoading(false)
        workbook.xlsx
          .writeBuffer()
          .then((buffer) =>
            saveAs(
              new Blob([buffer]),
              `アップロード情報（${
                schoolCategoryCode === 'B1'
                  ? '小学校'
                  : schoolCategoryCode === 'C1'
                  ? '中学校'
                  : '高校'
              }の個人単位）.xlsx`,
            ),
          )
      })
      .catch((err) => setLoading(false))
  }

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img className="w-72 mx-auto z-10" src={Fitness} alt="体力診断ゾーン" />
      </header>
      <div className="grid grid-cols-3 gap-8">
        <Anchor
          className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
          to="/file-uploads/metadata"
        >
          アップロード情報（学校単位）
        </Anchor>
        <Anchor
          className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
          to="/file-uploads/results"
        >
          アップロード情報（個人単位）
        </Anchor>
        <Anchor
          className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
          onClick={handleDownloadAllMetadata}
          to="#"
        >
          アップロード情報（すべての学校単位）
        </Anchor>
        <Anchor
          className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
          onClick={() => !loading && handleDownloadAllResults('B1')}
          to="#"
        >
          {loading && schoolCategory === 'B1'
            ? 'Downloading...'
            : 'アップロード情報（小学校の個人単位）'}
        </Anchor>
        <Anchor
          className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
          onClick={() => !loading && handleDownloadAllResults('C1')}
          to="#"
        >
          {loading && schoolCategory === 'C1'
            ? 'Downloading...'
            : 'アップロード情報（中学校の個人単位）'}
        </Anchor>
        <Anchor
          className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
          onClick={() => !loading && handleDownloadAllResults('D1')}
          to="#"
        >
          {loading && schoolCategory === 'D1'
            ? 'Downloading...'
            : 'アップロード情報（高校の個人単位）'}
        </Anchor>
      </div>
    </TochigiLayout>
  )
}

export default SchoolUploadPage
