import { TochigiLayout } from '../layout/TochigiLayout'

import { useFetchReportsBody } from '../../providers/TochigiProvider'
import { useState } from 'react'
import { Select } from 'antd'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
const { Option } = Select

const ReportTableData = ({
  data,
  className,
}: {
  data: any
  className: string
}) => {
  if (!data) return null
  const formatter = new Intl.NumberFormat('ja-JP')
  return (
    <tr className={className}>
      <td className={className}>{data.grade}</td>
      <td className={className}>{formatter.format(data.sampleCount! ?? 0)}</td>
      <td className={className}>{data.heightAvg?.toFixed(2)}</td>
      <td className={className}>{data.weightAvg?.toFixed(2)}</td>
      <td className={className}>{data.heightDev?.toFixed(2)}</td>
      <td className={className}>{data.weightDev?.toFixed(2)}</td>
    </tr>
  )
}

type TableProps = {
  gender: 1 | 2
  year: number
}

const ReportBodyTable = ({ gender, year }: TableProps) => {
  const { value: report, loading } = useFetchReportsBody(gender, year)

  if (loading) {
    return <Loading />
  }

  if (!report) {
    return <NoData />
  }

  const tableClasses =
    gender === 1 ? 'table-border-male' : 'table-border-female'
  const {
    elementarySchoolReport,
    juniorHighSchoolReport,
    allDayHighSchoolReport,
    fixTimeHighSchoolReport,
  } = report

  return (
    <table className="table border-collapse">
      <thead>
        <tr className="border border-white">
          <th rowSpan={2} className={tableClasses}>
            校種
          </th>
          <th rowSpan={2} className={tableClasses}>
            学年
          </th>
          <th rowSpan={2} className={tableClasses}>
            標本数
          </th>
          <th colSpan={2} className={tableClasses}>
            平均値
          </th>
          <th colSpan={2} className={tableClasses}>
            標準偏差
          </th>
        </tr>
        <tr className="border border-white">
          <th className={tableClasses}>身長(cm)</th>
          <th className={tableClasses}>体重(kg)</th>
          <th className={tableClasses}>身長(cm)</th>
          <th className={tableClasses}>体重(kg)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th rowSpan={7} className={tableClasses}>
            小学校
          </th>
        </tr>
        {elementarySchoolReport?.reports?.map((report) => (
          <ReportTableData className={tableClasses} data={report} />
        ))}
        <tr>
          <th rowSpan={4} className={tableClasses}>
            中学校
          </th>
        </tr>
        {juniorHighSchoolReport?.reports?.map((report) => (
          <ReportTableData className={tableClasses} data={report} />
        ))}
        <tr>
          <th rowSpan={4} className={tableClasses}>
            高等学校
          </th>
        </tr>
        {allDayHighSchoolReport?.reports?.map((report) => (
          <ReportTableData className={tableClasses} data={report} />
        ))}
        <tr>
          <th rowSpan={5} className={tableClasses}>
            高等学校
            <br />
            (定時制)
          </th>
        </tr>
        {fixTimeHighSchoolReport?.reports?.map((report) => (
          <ReportTableData className={tableClasses} data={report} />
        ))}
      </tbody>
    </table>
  )
}

export function ReportBodyPage() {
  const [year, setYear] = useState(2024)
  return (
    <TochigiLayout>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <div className="mb-10">
        <h1 className="text-2xl">男子</h1>
        <ReportBodyTable key={`male_${year}`} gender={1} year={year} />
      </div>
      <div>
        <h1 className="text-2xl">女子</h1>
        <ReportBodyTable key={`female_${year}`} gender={2} year={year} />
      </div>
    </TochigiLayout>
  )
}
