import { Card } from 'antd'
import {
  RadarChart,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { ScoreRadarChartProps } from '../../utils'

const ScoreRadarChart = ({ title, data, radarColor }: ScoreRadarChartProps) => {
  const radarChartProps = {
    width: 0,
    height: 0,
    data: data,
  }
  const radarChart = (
    <RadarChart {...radarChartProps}>
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis angle={75} tickCount={10} domain={[40, 55]} />
      <Radar
        name="県平均"
        dataKey="prefecture"
        stroke={radarColor}
        fill={radarColor}
        fillOpacity={0.2}
        isAnimationActive
      />
      <Radar
        name="全国平均"
        dataKey="national"
        stroke="#FF0000"
        fillOpacity={0}
        isAnimationActive
      />
      <Legend />
      <Tooltip />
    </RadarChart>
  )

  return (
    <Card title={title} style={{ textAlign: 'center' }}>
      <ResponsiveContainer height={420} width="100%">
        {radarChart}
      </ResponsiveContainer>
    </Card>
  )
}

export default ScoreRadarChart
