import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

import { TochigiProvider } from './providers/TochigiProvider'

import { NotFoundPage } from './components/pages/NotFoundPage'
import HomePage from './components/pages/HomePage'
import { NewChallengePage } from './components/pages/NewChallengePage'
import { EditChallengePage } from './components/pages/EditChallengePage'
import { ChallengesPage } from './components/pages/ChallengesPage'
import { RestorePasswordPage } from './components/pages/RestorePasswordPage'
import { ReportCountPage } from './components/pages/ReportCountPage'
import { ReportBodyPage } from './components/pages/ReportBodyPage'
import { ReportTestPage } from './components/pages/ReportTestPage'
import { ReportTestRankPage } from './components/pages/ReportTestRankPage'
import { ReportQuestionnairePage } from './components/pages/ReportQuestionnairePage'
import { ReportScoreChartPage } from './components/pages/ReportScoreChartPage'
import { ReportTestComparisonPage } from './components/pages/ReportTestComparisonPage'
import { ReportCrossAnalysisPage } from './components/pages/ReportCrossAnalysisPage'
import { ReportCrossAnalysisGraphPage } from './components/pages/ReportCrossAnalysisGraphPage'
import { ReportSchoolPerformanceListPage } from './components/pages/ReportSchoolPerformance'
import { ReportSchoolPerformanceProgressListPage } from './components/pages/ReportSchoolPerformanceProgress'
import SchoolUploadPage from './components/pages/SchoolUploadPage'
import UploadMetadataPage from './components/pages/UploadMetadataPage'
import UploadResultsPage from './components/pages/UploadResultsPage'

function App() {
  return (
    <TochigiProvider>
      <Router>
        <Switch>
          <Route path="/reports">
            <Switch>
              <Route path="/reports/count">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportCountPage />
                </Protect>
              </Route>
              <Route path="/reports/body">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportBodyPage />
                </Protect>
              </Route>
              <Route path="/reports/test">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportTestPage />
                </Protect>
              </Route>
              <Route path="/reports/test-rank">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportTestRankPage />
                </Protect>
              </Route>
              <Route path="/reports/score-chart">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportScoreChartPage />
                </Protect>
              </Route>
              <Route path="/reports/comparison">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportTestComparisonPage />
                </Protect>
              </Route>
              <Route path="/reports/questionnaire">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportQuestionnairePage />
                </Protect>
              </Route>
              <Route path="/reports/cross-analysis">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportCrossAnalysisPage />
                </Protect>
              </Route>
              <Route path="/reports/cross-analysis-graph">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportCrossAnalysisGraphPage />
                </Protect>
              </Route>
              <Route path="/reports/school-performance">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportSchoolPerformanceListPage />
                </Protect>
              </Route>
              <Route path="/reports/school-performance-progress">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ReportSchoolPerformanceProgressListPage />
                </Protect>
              </Route>
            </Switch>
          </Route>
          <Route path="/challenges">
            <Switch>
              <Route path="/challenges/new">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <NewChallengePage />
                </Protect>
              </Route>
              <Route path="/challenges/:challengeId">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <EditChallengePage />
                </Protect>
              </Route>
              <Route exact path="/challenges">
                {/* 1290qwopASKL */}
                <Protect
                  sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
                  boxTitle="パスワードを入力してください。"
                  inputPlaceholder="パスワード"
                  buttonLabel="送信"
                  blur={true}
                >
                  <ChallengesPage />
                </Protect>
              </Route>
            </Switch>
          </Route>
          <Route path="/file-uploads/metadata">
            {/* 1290qwopASKL */}
            <Protect
              sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
              boxTitle="パスワードを入力してください。"
              inputPlaceholder="パスワード"
              buttonLabel="送信"
              blur={true}
            >
              <UploadMetadataPage />
            </Protect>
          </Route>
          <Route path="/file-uploads/results">
            {/* 1290qwopASKL */}
            <Protect
              sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
              boxTitle="パスワードを入力してください。"
              inputPlaceholder="パスワード"
              buttonLabel="送信"
              blur={true}
            >
              <UploadResultsPage />
            </Protect>
          </Route>
          <Route path="/file-uploads">
            {/* 1290qwopASKL */}
            <Protect
              sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
              boxTitle="パスワードを入力してください。"
              inputPlaceholder="パスワード"
              buttonLabel="送信"
              blur={true}
            >
              <SchoolUploadPage />
            </Protect>
          </Route>
          <Route path="/restore-password">
            {/* 1290qwopASKL */}
            <Protect
              sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
              boxTitle="パスワードを入力してください。"
              inputPlaceholder="パスワード"
              buttonLabel="送信"
              blur={true}
            >
              <RestorePasswordPage />
            </Protect>
          </Route>
          <Route exact path="/">
            {/* 1290qwopASKL */}
            <Protect
              sha512="b68d2d44c2e1de83c023a3aee02c4f79ff9a655649121ff78016930b7d576ec22625e97aeb283097967a5734c5e54803ff5022f8dc79098eef99ad092a880634"
              boxTitle="パスワードを入力してください。"
              inputPlaceholder="パスワード"
              buttonLabel="送信"
              blur={true}
            >
              <HomePage />
            </Protect>
          </Route>
          <Route path="/">
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
    </TochigiProvider>
  )
}

export default App
