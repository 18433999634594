import { createContext, useCallback } from 'react'

import axios from 'axios'

import { useAsync } from '@sergeimeza/foundation-react'
import { API_URL_TOCHIGI } from '../utils'

const TochigiAPI = axios.create({
  baseURL: API_URL_TOCHIGI,
})

type TochigiValue = {}

const initialState: TochigiValue = {}

export const TochigiContext = createContext<TochigiValue>(initialState)

export const useSearchUser = () => {
  const searchUser = useCallback(async (username) => {
    try {
      let queryParams = new URLSearchParams()
      queryParams.set('username', username)
      const result = await TochigiAPI.get(
        `/user/username?${queryParams.toString()}`,
      )
      return result.data?.data
    } catch (e) {
      console.log(e)
      return null
    }
  }, [])

  return { searchUser }
}

export const useFetchReportsCount = (year: number = 2024) =>
  useAsync(async () => {
    const result = await TochigiAPI.post('/reports/count?v=1', {
      year,
    })

    return result.data
  }, [year])

export const useFetchReportsBody = (gender: 1 | 2, year: number = 2024) =>
  useAsync(async () => {
    const result = await TochigiAPI.post('/reports/body?v=1', {
      year,
      gender,
    })

    return result.data
  })

export const useFetchReportsTest = (gender: 1 | 2, year: number = 2024) =>
  useAsync(async () => {
    const result = await TochigiAPI.post('/reports/test?v=1', {
      year,
      gender,
    })

    return result.data
  })

export const useFetchReportsTestRank = (year: number = 2024) =>
  useAsync(async () => {
    const [result1, result2] = await Promise.all([
      TochigiAPI.post('/reports/test_rank?v=1', {
        year,
        gender: 1,
      }),
      TochigiAPI.post('/reports/test_rank?v=1', {
        year,
        gender: 2,
      }),
    ])

    return [result1.data, result2.data]
  })

export const useFetchReportsScoreChart = (year: number = 2024) =>
  useAsync(async () => {
    const [result1, result2] = await Promise.all([
      TochigiAPI.post('/reports/test_score_chart?v=1', {
        year,
        gender: 1,
      }),
      TochigiAPI.post('/reports/test_score_chart?v=1', {
        year,
        gender: 2,
      }),
    ])

    return [result1.data, result2.data]
  })

export const useFetchReportsTestComparison = (
  gender: 1 | 2,
  year: number = 2024,
) =>
  useAsync(async () => {
    const result1 = await TochigiAPI.post('/reports/test_comparison?v=1', {
      year,
      gender,
    })

    return result1.data
  })

export const useFetchReportsQuestionnaire = (year: number = 2024) =>
  useAsync(async () => {
    const [result1, result2] = await Promise.all([
      TochigiAPI.post('/reports/questionnaire?v=1', {
        year,
        gender: 1,
      }),
      TochigiAPI.post('/reports/questionnaire?v=1', {
        year,
        gender: 2,
      }),
    ])

    return [result1.data, result2.data]
  })

export const useFetchReportsCrossAnalysis = (year: number = 2024) => {
  const {
    value: data,
    loading,
    error,
  } = useAsync(async () => {
    const [result1, result2] = await Promise.all([
      TochigiAPI.post('/reports/cross_analysis?v=1', {
        year,
        gender: 1,
      }),
      TochigiAPI.post('/reports/cross_analysis?v=1', {
        year,
        gender: 2,
      }),
    ])

    return [result1.data, result2.data]
  })

  return { data, loading, error }
}

export const useFetchReportsSchoolPerformance = () => {
  const {
    value: data,
    loading,
    error,
  } = useAsync(async () => {
    const result = await TochigiAPI.post('/reports/school_performance?v=1', {
      year: 2021,
    })

    return result.data
  })

  return { data, loading, error }
}

export const useFetchChallenges = () => {
  const {
    value: challenges,
    loading,
    error,
  } = useAsync(async () => {
    const result = await TochigiAPI.get('/challenges?v=1')
    return result.data?.data
  })

  return { challenges, loading, error }
}

export const useFetchUploads = () => {
  const {
    value: fileUploads,
    loading,
    error,
  } = useAsync(async () => {
    const result = await TochigiAPI.get('/file-uploads')
    return result.data?.data
  })

  return { fileUploads, loading, error }
}

export const useRegisterNewChallenge = () => {
  const registerNewChallenge = useCallback(async (payload) => {
    try {
      const result = await TochigiAPI.post('/challenges', { payload })
      return result.data?.data
    } catch (e) {
      console.log(e)
      return false
    }
  }, [])

  return { registerNewChallenge }
}

export const useEditChallenge = () => {
  const editChallenge = useCallback(
    async (challengeId: string, payload: any) => {
      const result = await TochigiAPI.post(`/challenges/${challengeId}`, {
        payload,
      })
      return result.data?.data
    },
    [],
  )

  return { editChallenge }
}

export const useDeleteChallenge = () => {
  const deleteChallenge = useCallback(async (challengeId: string) => {
    const result = await TochigiAPI.delete(`/challenges/${challengeId}`)
    return result.data?.data
  }, [])

  return { deleteChallenge }
}

export const useDeleteFileId = () => {
  const deleteFileId = useCallback(async (fileId: string, year: number) => {
    const result = await TochigiAPI.delete(
      `/file-uploads/${fileId}?year=${year}`,
    )
    return result.data?.data
  }, [])

  return { deleteFileId }
}

export const TochigiProvider = ({ children }) => {
  const value: TochigiValue = {}

  return (
    <TochigiContext.Provider value={value}>{children}</TochigiContext.Provider>
  )
}
