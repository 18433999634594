import { TochigiLayout } from '../layout/TochigiLayout'

import { useForm } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import Fitness from '../../assets/images/fitness.png'
import { Button, Input, Table } from '@sergeimeza/uikit-react'
import { useSearchUser } from '../../providers/TochigiProvider'
import { useState } from 'react'
import { TableColumnData } from '@sergeimeza/uikit-react/lib/Elements/Table/Table'
import { useHistory } from 'react-router'

let restorePasswordSchema = yup
  .object({
    username: yup.string().max(100).required(),
  })
  .required()

export function RestorePasswordPage() {
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(restorePasswordSchema),
  })

  const [user, setUser] = useState<any>(null)
  const { searchUser } = useSearchUser()

  async function onRestorePassword(data) {
    const user = await searchUser(data.username)

    if (!user) {
      alert('ユーザーを見つかりませんでした')
    } else {
      setUser(user)
    }
  }

  const columns: TableColumnData[] = [
    {
      key: 'username',
      label: 'ユーザーネーム',
      value: ['username'],
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      key: 'password',
      label: 'パスワード',
      value: ['password'],
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
  ]

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img className="w-72 mx-auto z-10" src={Fitness} alt="体力診断ゾーン" />
      </header>
      {user ? (
        <Table
          data={[user].map((user) => ({ key: user._id, ...user.attributes }))}
          columns={columns}
        />
      ) : (
        <div>
          <form onSubmit={handleSubmit(onRestorePassword)}>
            <div className="border border-primary-500 overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-4">
                    <Input
                      type="text"
                      label="ユーザーネーム"
                      cornerHint="必須"
                      register={register('username')}
                      error={errors.username?.message}
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <div className="space-x-4">
                  <Button
                    className="inline-flex justify-center"
                    type="submit"
                    title="検索"
                    size={Button.size.xl}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      <div className="mt-8 flex justify-end">
        <Button
          buttonType={Button.buttonType.secondary}
          onClick={() => history.goBack()}
        >
          戻る
        </Button>
      </div>
    </TochigiLayout>
  )
}
