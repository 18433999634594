import { Fragment, useEffect, useState } from 'react'

import { TochigiLayout } from '../layout/TochigiLayout'

import { useFetchReportsCrossAnalysis } from '../../providers/TochigiProvider'
import { Select } from 'antd'
import CrossAnalysisLineChart from '../components/CrossAnalysisLineChart'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { getQuestionnaires, Questionnaire } from '../../utils/const'

const { Option } = Select

const scoreTypes = {
  height: '身長',
  weight: '体重',
  grip: '握力',
  sitUps: '上体起こし',
  bending: '長座体前屈',
  sideJump: '反復横跳び',
  shuttleRun: 'シャトルラン',
  sprintRun: '50m走',
  standingJump: '立ち幅跳び',
  handballThrow: 'ボール投げ',
  totalScore: '得点合計',
}

const schoolCategories = [
  'ELEMENTARY_SCHOOL',
  'JUNIOR_HIGH_SCHOOL',
  'ALL_DAY_HIGH_SCHOOL',
]

type ReportScoreByAnswer = {
  answer: string
  score: number
}

type ReportScoreByGrade = {
  schoolCategory: string
  grade: number
  answers: ReportScoreByAnswer[]
}

type ReportScoreByGender = {
  gender: number
  grades: ReportScoreByGrade[]
}

export type ReportScoreByScoreType = {
  scoreType: string
  genders: ReportScoreByGender[]
}

type ChartsProps = {
  schoolCategories: string[]
  question: Questionnaire | undefined
  year: number
}

const CrossAnalysisLineCharts = ({
  schoolCategories,
  question,
  year,
}: ChartsProps) => {
  const { data, loading } = useFetchReportsCrossAnalysis(year)

  const [targetData, setTargetData] = useState<ReportScoreByScoreType[]>([])

  useEffect(() => {
    if (loading) return
    if (!data?.length) return
    if (!question) return

    let maleReports = data[0]
    let femaleReports = data[1]
    let maleReport = maleReports?.find((r) => r.dataKey === question.dataKey)
    let femaleReport = femaleReports?.find(
      (r) => r.dataKey === question.dataKey,
    )

    if (!maleReport) return
    if (!femaleReport) return

    let computedData: any = Object.keys(scoreTypes).map((scoreType) => {
      let maleGrades = maleReport!.grades!.map((g) => {
        let answers = g.answers!.map((a) => {
          let answer = question.answers[a.answer! - 1].replace(/\(.*\)/i, '')

          let score: any = +a.averages!.find(
            (av) => av.scoreType === scoreType,
          )!.score
          score = (score ?? 0).toFixed(1)

          return {
            answer,
            score,
          }
        })
        return {
          schoolCategory: g.school_category,
          grade: g.grade,
          answers,
        }
      })

      let femaleGrades = femaleReport!.grades!.map((g) => {
        let answers = g.answers!.map((a) => {
          let answer = question.answers[a.answer! - 1].replace(/\(.*\)/i, '')

          let score: any = +a.averages!.find(
            (av) => av.scoreType === scoreType,
          )!.score
          score = (score ?? 0).toFixed(1)

          return {
            answer,
            score,
          }
        })

        return {
          schoolCategory: g.school_category,
          grade: g.grade,
          answers,
        }
      })

      maleGrades = maleGrades.slice().sort((a, b) => {
        let gToInt = (cat, g) => {
          if (cat === 'ELEMENTARY_SCHOOL') {
            return g
          } else if (cat === 'JUNIOR_HIGH_SCHOOL') {
            return 6 + g
          } else {
            return 9 + g
          }
        }

        let _a = gToInt(a.schoolCategory, a.grade)
        let _b = gToInt(b.schoolCategory, b.grade)

        return _a - _b
      })

      femaleGrades = femaleGrades.slice().sort((a, b) => {
        let gToInt = (cat, g) => {
          if (cat === 'ELEMENTARY_SCHOOL') {
            return g
          } else if (cat === 'JUNIOR_HIGH_SCHOOL') {
            return 6 + g
          } else {
            return 9 + g
          }
        }

        let _a = gToInt(a.schoolCategory, a.grade)
        let _b = gToInt(b.schoolCategory, b.grade)

        return _a - _b
      })

      let male = {
        gender: 'MALE',
        grades: maleGrades,
      }

      let female = {
        gender: 'FEMALE',
        grades: femaleGrades,
      }

      return {
        scoreType,
        genders: [male, female],
      }
    })

    setTargetData(computedData)
  }, [question, data, loading])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      {targetData.map((d) => (
        <CrossAnalysisLineChart
          data={d}
          visibleCategories={
            ((question.dataKey === 'q4' && year !== 2024) || (question.dataKey === 'q8' && year === 2024))
              ? ['ELEMENTARY_SCHOOL']
              : question.dataKey === 'q9'
              ? ['JUNIOR_HIGH_SCHOOL', 'ALL_DAY_HIGH_SCHOOL']
              : schoolCategories
          }
          key={d.scoreType}
        />
      ))}
    </>
  )
}

export function ReportCrossAnalysisGraphPage() {
  const [selectedQuestion, setSelectedQuestion] = useState('q1')
  const [question, setQuestion] = useState<Questionnaire>()

  const handleChangeQuestion = (v) => setSelectedQuestion(v)
  const [year, setYear] = useState(2024)

  useEffect(() => {
    const q = getQuestionnaires(year).find((q) => q.dataKey === selectedQuestion)
    if (q) {
      setQuestion(q)
    }
  }, [selectedQuestion, year])

  return (
    <TochigiLayout>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <Select
        value={selectedQuestion}
        onChange={handleChangeQuestion}
        style={{ width: 320 }}
      >
        {getQuestionnaires(year).map((q) => (
          <Option value={q.dataKey} key={q.dataKey}>
            {q.question}
          </Option>
        ))}
      </Select>

      <CrossAnalysisLineCharts
        key={year}
        question={question}
        schoolCategories={schoolCategories}
        year={year}
      />
    </TochigiLayout>
  )
}
