import { TochigiLayout } from '../layout/TochigiLayout'

import { useFetchReportsTest } from '../../providers/TochigiProvider'
import { useState } from 'react'

import { Radio, RadioChangeEvent, Select } from 'antd'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'

const { Option } = Select

type ReportTableDataProps = {
  data: any
  showType: string
  className: string
}

const ReportTableData = ({
  data,
  showType,
  className,
}: ReportTableDataProps) => {
  const formatter = new Intl.NumberFormat('ja-JP')
  const report = data[showType]
  return (
    <tr className={className}>
      <td className={className}>{data.grade}</td>
      <td className={className}>
        {formatter.format((report?.grip ?? 0).toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format((report?.sitUps ?? 0).toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format((report?.bending ?? 0).toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format((report?.sideJump ?? 0).toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format((report?.shuttleRun ?? 0).toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format((report?.sprintRun ?? 0).toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format((report?.standingJump ?? 0).toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format((report?.handballThrow ?? 0).toFixed(2))}
      </td>
      <td className={className}>
        {formatter.format((report?.totalScore ?? 0).toFixed(2))}
      </td>
    </tr>
  )
}

const styleForReport = {
  fontSize: 11,
  width: 90,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr className="border border-white">
        <th className={className} style={{ fontSize: 12, width: 96 }}>
          校種
        </th>
        <th className={className} style={styleForReport}>
          学年
        </th>
        <th className={className} style={styleForReport}>
          握力
          <br />
          (kg)
        </th>
        <th className={className} style={styleForReport}>
          上体起こし
          <br />
          (回)
        </th>
        <th className={className} style={styleForReport}>
          長座体前屈
          <br />
          (cm)
        </th>
        <th className={className} style={styleForReport}>
          反復横跳び
          <br />
          (点)
        </th>
        <th className={className} style={styleForReport}>
          シャトルラン
          <br />
          (回)
        </th>
        <th className={className} style={styleForReport}>
          50m走
          <br />
          (秒)
        </th>
        <th className={className} style={styleForReport}>
          立ち幅跳び
          <br />
          (cm)
        </th>
        <th className={className} style={styleForReport}>
          ボール投げ
          <br />
          (m)
        </th>
        <th className={className} style={styleForReport}>
          得点合計
          <br />
          (点)
        </th>
      </tr>
    </thead>
  )
}

const TableBody = ({
  className,
  data,
  showType,
}: {
  className: string
  data: any
  showType: string
}) => {
  if (!data) return null

  return (
    <tbody>
      <tr>
        <th rowSpan={7} className={className}>
          小学校
        </th>
      </tr>
      {data.elementarySchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      <tr>
        <th rowSpan={4} className={className}>
          中学校
        </th>
      </tr>
      {data.juniorHighSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      <tr>
        <th rowSpan={4} className={className}>
          高校
        </th>
      </tr>
      {data.allDayHighSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      <tr>
        <th rowSpan={5} className={className}>
          高校
          <br />
          (定時制)
        </th>
      </tr>
      {data.fixTimeHighSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
    </tbody>
  )
}

type TableProps = {
  gender: 1 | 2
  showType: string
  year: number
}

const ReportTestTable = ({ gender, showType, year }: TableProps) => {
  const { value: report, loading } = useFetchReportsTest(gender, year)

  if (loading) {
    return <Loading />
  }

  if (!report) {
    return <NoData />
  }

  const tableClasses =
    gender === 1 ? 'table-border-male' : 'table-border-female'

  return (
    <table className="table border-collapse">
      <TableHeader className={tableClasses} />
      <TableBody className={tableClasses} data={report} showType={showType} />
    </table>
  )
}

export function ReportTestPage() {
  const [showType, setShowType] = useState('average')

  const handleChangeShowType = (e: RadioChangeEvent) => {
    setShowType(e.target.value)
  }

  const [year, setYear] = useState(2024)

  return (
    <TochigiLayout>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>

      <div className="mb-10">
        <Radio.Group onChange={handleChangeShowType} value={showType}>
          <Radio value="average" className="dark:text-white">
            平均値
          </Radio>
          <Radio value="deviation" className="dark:text-white">
            標準偏差
          </Radio>
        </Radio.Group>
      </div>
      <div className="mb-10">
        <h1 className="text-2xl">男子</h1>
        <ReportTestTable
          key={`male_${year}`}
          gender={1}
          showType={showType}
          year={year}
        />
      </div>
      <div>
        <h1 className="text-2xl">女子</h1>
        <ReportTestTable
          key={`female_${year}`}
          gender={2}
          showType={showType}
          year={year}
        />
      </div>
    </TochigiLayout>
  )
}
