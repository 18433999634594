import { Button, Input, SelectMenu } from '@sergeimeza/uikit-react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import Challenge from '../../assets/images/challenge.png'
import { useRegisterNewChallenge } from '../../providers/TochigiProvider'
import { TochigiLayout } from '../layout/TochigiLayout'

// let challengeSchema = yup
//   .object({
//     challengeName: yup.string().max(100).required(),
//     challengeDescription: yup.string().max(100).optional(),
//     challengeRules: yup.string().max(500).optional(),
//     challengeUnit: yup.string().max(5).required(),
//     challengeType: yup.string().required(),
//     challengeOrder: yup.string().required(),
//   })
//   .required()

export function NewChallengePage() {
  const history = useHistory()

  const { registerNewChallenge } = useRegisterNewChallenge()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      challengeType: 'GROUP',
      challengeOrder: 'ASC',
    },
    // resolver: yupResolver(challengeSchema),
  })

  async function onNewChallengeSubmit(data: any) {
    await registerNewChallenge(data)
    alert('新しいチャレンジを追加しました。')
    history.push('/challenges')
  }

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="w-96 mx-auto z-10"
          src={Challenge}
          alt="チャレンジランキングゾーン"
        />
      </header>
      <div>
        <form onSubmit={handleSubmit(onNewChallengeSubmit)}>
          <div className="border border-primary-500 overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-4">
                  <Input
                    type="text"
                    label="チャレンジ名"
                    cornerHint="必須"
                    register={register('challengeName')}
                    error={errors.challengeName?.message}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <Input.TextArea
                    label="チャレンジの説明文（50字程度）"
                    description=""
                    register={register('challengeDescription')}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <Input.TextArea
                    label="ゲームの仕方とルール（500字まで）"
                    description=""
                    register={register('challengeRules')}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <Input
                    type="text"
                    label="チャレンジの単位（「回」、「点」、「秒」など）"
                    placeholder="「回」、「点」、「秒」など"
                    cornerHint="必須"
                    register={register('challengeUnit')}
                    error={errors.challengeUnit?.message}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <SelectMenu
                    label="記録の順番"
                    name="challengeType"
                    options={[
                      { name: 'みんなでチャレンジ', value: 'GROUP' },
                      { name: '一人でチャレンジ', value: 'INDIVIDUAL' },
                    ]}
                    cornerHint="必須"
                    selectedItem={{
                      name: 'みんなでチャレンジ',
                      value: 'GROUP',
                    }}
                    register={register('challengeType')}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <SelectMenu
                    label="記録の順番"
                    name="challengeOrder"
                    options={[
                      { name: '大きい方が良い', value: 'DESC' },
                      { name: '小さい方が良い', value: 'ASC' },
                    ]}
                    cornerHint="必須"
                    selectedItem={{ name: '大きい方が良い', value: 'DESC' }}
                    register={register('challengeOrder')}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <div className="space-x-4">
                <Button
                  className="inline-flex justify-center"
                  type="submit"
                  title="登録"
                  size={Button.size.xl}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </TochigiLayout>
  )
}
