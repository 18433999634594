import type { FC } from 'react'
import type { FallbackProps } from 'react-error-boundary'

export const ErrorFallback: FC<FallbackProps> = ({ error }) => {
  return (
    <div className="p-16 border-red-400 border-solid border-8 text-rose-900">
      <p className="text-2xl font-bold border-8">エラーが発生しました。</p>

      <p className="font-bold">メッセージ</p>
      <pre>{error.message}</pre>

      <p className="font-bold">詳細</p>
      <pre>{error.stack}</pre>
    </div>
  )
}
