import { Anchor, Button, Table } from '@sergeimeza/uikit-react'

import { TochigiLayout } from '../layout/TochigiLayout'

import Challenge from '../../assets/images/challenge.png'
import {
  useDeleteChallenge,
  useFetchChallenges,
} from '../../providers/TochigiProvider'
import { TableColumnData } from '@sergeimeza/uikit-react/lib/Elements/Table/Table'
import { useHistory } from 'react-router'

export function ChallengesPage() {
  const history = useHistory()
  const { challenges, loading, error } = useFetchChallenges()
  const { deleteChallenge } = useDeleteChallenge()

  function onChallengeEdit(challenge: any) {
    history.push(`/challenges/${challenge._id}`)
  }

  async function onChallengeDelete(challenge: any) {
    await deleteChallenge(challenge._id)
    window.location.reload()
  }

  const challengeColumns: TableColumnData[] = [
    {
      key: 'name',
      label: 'チャレンジ名',
      value: ['challengeName'],
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      key: 'unit',
      label: '単位',
      value: ['challengeUnit'],
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      key: 'order',
      label: '順番',
      value: ['challengeOrder'],
      render: (value) => (
        <Table.Cell>
          {value === 'DESC' ? '大きいがいい' : '小さいがいい'}
        </Table.Cell>
      ),
    },
    {
      key: 'actions',
      label: 'actions',
      labelHidden: true,
      render: (value) => (
        <Table.Cell>
          <div className="flex flex-col space-y-2">
            <Button
              onClick={() => onChallengeEdit(value)}
              buttonType={Button.buttonType.secondary}
            >
              詳細
            </Button>
            <Button
              onClick={() => onChallengeDelete(value)}
              buttonType={Button.buttonType.outline}
            >
              <div className="text-red-500">削除</div>
            </Button>
          </div>
        </Table.Cell>
      ),
    },
  ]

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="w-96 mx-auto z-10"
          src={Challenge}
          alt="チャレンジランキングゾーン"
        />
      </header>

      <h1 className="mb-4 text-2xl font-medium">チャレンジ一覧</h1>
      <div className="mb-10">
        {loading && <div>読み込み中</div>}
        {error && <div>エラー：{error.message}</div>}
        {challenges && (
          <Table
            data={challenges.map((challenge) => ({
              key: challenge._id,
              ...challenge,
            }))}
            columns={challengeColumns}
          />
        )}
      </div>

      <div className="flex justify-end">
        <Anchor
          className="text-white text-center bg-primary-500 hover:bg-primary-100 hovers:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
          to="/challenges/new"
        >
          新しいチャレンジを登録
        </Anchor>
      </div>
    </TochigiLayout>
  )
}
