export const testRankTotalRates = {
  "2023": [
    [10.83, 24.42, 32.47, 21.43, 10.86],
    [18.18, 26.57, 29.91, 18.7, 6.65],
    [19.58, 29.99, 32.49, 14.98, 2.95],
    [1.09, 7.03, 26.09, 38.28, 27.5],
  ],
  "2024": [
    [10.96, 24.05, 32.04, 21.52, 11.42],
    [19.24, 26.66, 29.24, 18.25, 6.62],
    [19.56, 29.64, 32.25, 15.21, 3.34],
    [1.21, 10.00, 29.39, 36.06, 23.33],
  ]
}