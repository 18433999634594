import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Input, SelectMenu } from '@sergeimeza/uikit-react'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'

import Challenge from '../../assets/images/challenge.png'
import {
  useEditChallenge,
  useFetchChallenges,
} from '../../providers/TochigiProvider'
import { TochigiLayout } from '../layout/TochigiLayout'

let challengeSchema = yup
  .object({
    challengeName: yup.string().max(100).required(),
    challengeDescription: yup.string().max(100).optional(),
    challengeRules: yup.string().max(500).optional(),
    challengeUnit: yup.string().max(5).required(),
    challengeType: yup.string().required(),
    challengeOrder: yup.string().required(),
  })
  .required()

export function EditChallengePage() {
  const history = useHistory()
  const { challengeId }: any = useParams()
  const { challenges, loading } = useFetchChallenges()
  const { editChallenge } = useEditChallenge()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(challengeSchema),
  })

  const challenge = useMemo(() => {
    if (!challenges) return null

    let challenge = challenges.find(
      (challenge) => challenge._id === challengeId,
    )
    reset({
      challengeName: challenge.challengeName,
      challengeDescription: challenge.challengeDescription,
      challengeRules: challenge.challengeRules,
      challengeUnit: challenge.challengeUnit,
      challengeOrder: challenge.challengeOrder,
    })
    return challenge
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenges, challengeId])

  async function onEditChallengeSubmit(data: any) {
    await editChallenge(challengeId, data)
    alert('チャレンジを編集しました。')
    history.push('/challenges')
  }

  if (loading) {
    return null
  }

  if (!challenge) {
    return null
  }

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="w-96 mx-auto z-10"
          src={Challenge}
          alt="チャレンジランキングゾーン"
        />
      </header>
      <div>
        <form onSubmit={handleSubmit(onEditChallengeSubmit)}>
          <div className="border border-primary-500 overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-4">
                  <Input
                    type="text"
                    label="チャレンジ名"
                    cornerHint="必須"
                    register={register('challengeName')}
                    error={errors.challengeName?.message}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <Input.TextArea
                    label="チャレンジの説明文（50字程度）"
                    description=""
                    register={register('challengeDescription')}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <Input.TextArea
                    label="ゲームの仕方とルール（500字まで）"
                    description=""
                    register={register('challengeRules')}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <Input
                    type="text"
                    label="チャレンジの単位（「回」、「点」、「秒」など）"
                    placeholder="「回」、「点」、「秒」など"
                    cornerHint="必須"
                    register={register('challengeUnit')}
                    error={errors.challengeUnit?.message}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <SelectMenu
                    label="記録の順番"
                    name="challengeType"
                    options={[
                      { name: 'みんなでチャレンジ', value: 'GROUP' },
                      { name: '一人でチャレンジ', value: 'INDIVIDUAL' },
                    ]}
                    cornerHint="必須"
                    selectedItem={{
                      name: 'みんなでチャレンジ',
                      value: 'GROUP',
                    }}
                    register={register('challengeType')}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <SelectMenu
                    label="記録の順番"
                    name="challengeOrder"
                    options={[
                      { name: '大きい方が良い', value: 'DESC' },
                      { name: '小さい方が良い', value: 'ASC' },
                    ]}
                    cornerHint="必須"
                    selectedItem={{ name: '大きい方が良い', value: 'DESC' }}
                    register={register('challengeOrder')}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <div className="space-x-4">
                <Button
                  className="inline-flex justify-center"
                  type="submit"
                  title="編集"
                  size={Button.size.xl}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </TochigiLayout>
  )
}
