import { TochigiLayout } from '../layout/TochigiLayout'

export const NotFoundPage: React.FC<any> = () => {
  return (
    <TochigiLayout>
      <section className="flex flex-col items-center text-center space-y-8">
        <div className="text-red-500 text-xl sm:text-2xl font-bold">
          お探しのページが見つかりませんでした
        </div>
        <div className="text-base pb-4 tracking-widest">
          <p>
            申し訳ございません。サイトのメンテナンスに伴い、
            <br />
            ページの移動や削除をしたため、既存のページを表示することができませんでした。
          </p>
        </div>
      </section>
    </TochigiLayout>
  )
}
