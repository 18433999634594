import { TochigiLayout } from '../layout/TochigiLayout'

import { useFetchReportsScoreChart } from '../../providers/TochigiProvider'
import { useEffect, useState } from 'react'
import ScoreRadarChart from '../components/ScoreRadarChart'
import { Select } from 'antd'
import { TScoreData, makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
const { Option } = Select

type TScoreEachStudent = {
  /**
   * 握力平均
   */
  grip: number
  /**
   * 上体起こし
   */
  sitUps: number
  /**
   * 長座体前屈
   */
  bending: number
  /**
   * 反復横とび
   */
  sideJump: number
  /**
   * シャトルラン
   */
  shuttleRun: number
  /**
   * 50m走
   */
  sprintRun: number
  /**
   * 立ち幅とび
   */
  standingJump: number
  /**
   * ボール投げ
   * same with `ballThrow`
   */
  handballThrow: number
  /**
   * 得点
   */
  totalScore: number
  /**
   * 身長
   */
  height: number
  /**
   * 体重
   */
  weight: number
}

type TScoreEachSchool = {
  reports: {
    grade: number
    prefecture: TScoreEachStudent
  }[]
}

type TScoreRes = {
  elementarySchoolReport: TScoreEachSchool
  juniorHighSchoolReport: TScoreEachSchool
  highSchoolReport: TScoreEachSchool
}

const scoreTypes = {
  totalScore: '得点合計',
  height: '身長',
  weight: '体重',
  grip: '握力',
  sitUps: '上体起こし',
  bending: '長座体前屈',
  sideJump: '反復横跳び',
  shuttleRun: 'シャトルラン',
  sprintRun: '50m走',
  standingJump: '立ち幅跳び',
  handballThrow: 'ボール投げ',
} as const

const maleColor = '#0000FF'
const femaleColor = '#FFAAAA'

type ScoreDataByGender = {
  gender: number
  scores: TScoreData[]
}

type ScoreDataByGrade = {
  schoolCategory: string
  grade: number
  genders: ScoreDataByGender[]
}

const categories = [
  'ELEMENTARY_SCHOOL',
  'JUNIOR_HIGH_SCHOOL',
  'ALL_DAY_HIGH_SCHOOL',
] as const

const schools = {
  elementarySchoolReport: 'ELEMENTARY_SCHOOL',
  juniorHighSchoolReport: 'JUNIOR_HIGH_SCHOOL',
  highSchoolReport: 'ALL_DAY_HIGH_SCHOOL',
} as const

const ReportScoreGraph = ({ year }: { year: number }) => {
  const { value: data, loading }: { value: TScoreRes[]; loading: boolean } =
    useFetchReportsScoreChart(year)

  const [graphData, setGraphData] = useState<ScoreDataByGrade[]>([])

  useEffect(() => {
    if (loading) return
    if (!data?.length) return

    const _data: ScoreDataByGrade[] = []

    const userGenders = [1, 2]

    categories.forEach((schoolCategory) => {
      const maxGrade = schoolCategory === 'ELEMENTARY_SCHOOL' ? 6 : 3
      for (let grade = 1; grade <= maxGrade; grade++) {
        _data.push({
          schoolCategory,
          grade,
          genders: userGenders.map((gender) => {
            return {
              gender,
              scores: [],
            }
          }),
        })
      }
    })

    Object.keys(schools).forEach((reportKey) => {
      const sc = schools[reportKey]
      userGenders.forEach((gender) => {
        const target = gender === 1 ? data[0] : data[1]
        const targetData = target[reportKey] as TScoreEachSchool

        targetData.reports.forEach(({ grade, prefecture }) => {
          if (!prefecture) return

          const destData = _data.find((d) => {
            return d.schoolCategory === sc && d.grade === grade
          })
          if (!destData) return

          const destDataByGender = destData.genders.find(
            (g) => g.gender === gender,
          )
          if (!destDataByGender) return

          Object.keys(scoreTypes).forEach((sc) => {
            destDataByGender.scores.push({
              subject: scoreTypes[sc],
              prefecture: (prefecture[sc] ?? 50).toFixed(2),
              national: 50,
            })
          })
        })
      })
    })
    setGraphData(_data)
  }, [data, loading])

  if (loading) {
    return <Loading />
  }

  if (!graphData?.length) {
    return <NoData />
  }

  return (
    <>
      {graphData.map((data) => (
        <div
          className="flex flex-row w-full"
          key={`${data.schoolCategory}_${data.grade}`}
        >
          {data.genders.map((g) => {
            let schoolName = '小学'
            if (data.schoolCategory === 'JUNIOR_HIGH_SCHOOL')
              schoolName = '中学'
            else if (data.schoolCategory === 'ALL_DAY_HIGH_SCHOOL')
              schoolName = '高校'

            const genderName = g.gender === 1 ? '男子' : '女子'
            const radarColor = g.gender === 1 ? maleColor : femaleColor
            const title = `${schoolName}${data.grade}年生/${genderName}`

            return (
              <div key={title} className="w-1/2">
                <ScoreRadarChart
                  title={title}
                  data={g.scores}
                  radarColor={radarColor}
                />
              </div>
            )
          })}
        </div>
      ))}
    </>
  )
}

export function ReportScoreChartPage() {
  const [year, setYear] = useState(2024)
  return (
    <TochigiLayout>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <ReportScoreGraph key={year} year={year} />
    </TochigiLayout>
  )
}
