import { TochigiLayout } from '../layout/TochigiLayout'

import { useFetchReportsTestRank } from '../../providers/TochigiProvider'
import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { testRankTotalRates } from './data/test-rank/totalRates'
const { Option } = Select

type ReportTableDataProps = {
  data: any
  className: string
}

const ranks = ['A', 'B', 'C', 'D', 'E'] as const

const schoolsTypeReport = [
  'elementarySchoolReport',
  'juniorHighSchoolReport',
  'allDayHighSchoolReport',
  'fixTimeHighSchoolReport',
] as const

const ReportTableData = ({ data, className }: ReportTableDataProps) => {
  return (
    <tr className={className}>
      <td className={className}>{data.grade === 0 ? '合計' : data.grade}</td>
      <td className={className}>{data.totalCount ?? 0}</td>
      {ranks.map((rank) => (
        <td className={className} key={`${data?.grade}_count_${rank}`}>
          {data.countByRank?.[`rank${rank}`] ?? 0}
        </td>
      ))}
      {ranks.map((rank) => (
        <td className={className} key={`${data.grade}_rate_${rank}`}>
          {((data?.rateByRank?.[`rank${rank}`] ?? 0) * 100).toFixed(2)}%
        </td>
      ))}
    </tr>
  )
}

const styleForReport = {
  fontSize: 12,
  width: 64,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr className={className}>
        <th
          className={className}
          style={{ fontSize: 12, width: 128 }}
          rowSpan={2}
        >
          校種
        </th>
        <th className={className} style={styleForReport} rowSpan={2}>
          学年
        </th>
        <th className={className} style={styleForReport} rowSpan={2}>
          実施人数
        </th>
        <th className={className} style={styleForReport} colSpan={5}>
          総合評価段階別人数
        </th>
        <th className={className} style={styleForReport} colSpan={5}>
          総合評価段階別割合
        </th>
      </tr>
      <tr className={className}>
        <th className={className} style={styleForReport}>
          A
        </th>
        <th className={className} style={styleForReport}>
          B
        </th>
        <th className={className} style={styleForReport}>
          C
        </th>
        <th className={className} style={styleForReport}>
          D
        </th>
        <th className={className} style={styleForReport}>
          E
        </th>
        <th className={className} style={styleForReport}>
          A
        </th>
        <th className={className} style={styleForReport}>
          B
        </th>
        <th className={className} style={styleForReport}>
          C
        </th>
        <th className={className} style={styleForReport}>
          D
        </th>
        <th className={className} style={styleForReport}>
          E
        </th>
      </tr>
    </thead>
  )
}

type TableBodyProps = {
  className: string
  data: any
}

const TableBody = ({ className, data }: TableBodyProps) => {
  const {
    elementarySchoolReport,
    juniorHighSchoolReport,
    allDayHighSchoolReport,
    fixTimeHighSchoolReport,
  } = data
  if (
    !elementarySchoolReport?.reports ||
    !juniorHighSchoolReport?.reports ||
    !allDayHighSchoolReport?.reports ||
    !fixTimeHighSchoolReport?.reports
  ) {
    return null
  }
  return (
    <tbody>
      <tr>
        <th
          rowSpan={elementarySchoolReport.reports.length + 1}
          className={className}
        >
          小学校
        </th>
      </tr>
      {elementarySchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={juniorHighSchoolReport.reports.length + 1}
          className={className}
        >
          中学校
        </th>
      </tr>
      {juniorHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={allDayHighSchoolReport.reports.length + 1}
          className={className}
        >
          高校
        </th>
      </tr>
      {allDayHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={fixTimeHighSchoolReport.reports.length + 1}
          className={className}
        >
          高校(定時制)
        </th>
      </tr>
      {fixTimeHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
    </tbody>
  )
}

const makeReportTestRankByGrade = () => {
  return {
    grade: 0,
    totalCount: 0,
    countByRank: {
      rankA: 0,
      rankB: 0,
      rankC: 0,
      rankD: 0,
      rankE: 0,
    },
    rateByRank: {
      rankA: 0,
      rankB: 0,
      rankC: 0,
      rankD: 0,
      rankE: 0,
    },
  }
}

const fixedTotalRateByRank = {
  elementarySchoolReport: {
    rankA: 0.1007,
    rankB: 0.2355,
    rankC: 0.335,
    rankD: 0.2195,
    rankE: 0.1092,
  },
  juniorHighSchoolReport: {
    rankA: 0.1734,
    rankB: 0.2632,
    rankC: 0.3064,
    rankD: 0.191,
    rankE: 0.066,
  },
  allDayHighSchoolReport: {
    rankA: 0.1976,
    rankB: 0.3059,
    rankC: 0.3232,
    rankD: 0.1484,
    rankE: 0.0249,
  },
  fixTimeHighSchoolReport: {
    rankA: 0.0101,
    rankB: 0.1042,
    rankC: 0.3328,
    rankD: 0.3697,
    rankE: 0.1832,
  },
}

const ReportTestTable = ({ year }: { year: number }) => {
  const { value: resArr, loading } = useFetchReportsTestRank(year)

  const [dataForTotal, setDataForTotal] = useState<any>()

  useEffect(() => {
    if (!resArr || !resArr.length) return

    const totalData = {
      elementarySchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      juniorHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      allDayHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      fixTimeHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
    }

    resArr.forEach((res) => {
      schoolsTypeReport.forEach((schoolType, schoolTypeIdx) => {
        if (!res) return
        const targetData = res[schoolType].reports
        const destData = totalData[schoolType].reports[0]
        targetData.forEach((t) => {
          destData.totalCount += t.totalCount
          ranks.forEach((rank) => {
            destData.countByRank[`rank${rank}`] +=
              t?.countByRank?.[`rank${rank}`] ?? 0
          })
        })

        if (Object.keys(testRankTotalRates).includes(String(year))) {
          ranks.forEach((rank, rankIdx) => {
            destData.rateByRank[`rank${rank}`] =
            testRankTotalRates[String(year)][schoolTypeIdx][rankIdx] / 100
          })
        } else if (year >= 2022) {
          ranks.forEach((rank) => {
            destData.rateByRank[`rank${rank}`] =
              fixedTotalRateByRank[schoolType][`rank${rank}`]
          })
        }
      })
    })
    setDataForTotal(totalData)
    console.log('Total data: ' + JSON.stringify(totalData))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resArr, setDataForTotal])

  if (loading) {
    return <Loading />
  }

  if (!resArr || !resArr[0] || !resArr[1]) {
    return <NoData />
  }
  if (!dataForTotal) {
    return <NoData />
  }

  const maleData = resArr[0]
  const femaleData = resArr[1]

  return (
    <>
      <div>男子</div>
      <table className="table border-collapse">
        <TableHeader className="table-border-male" />
        <TableBody className="table-border-male" data={maleData} />
      </table>
      <div className="pt-4" style={{ pageBreakBefore: 'always' }}>
        女子
      </div>
      <table className="table border-collapse">
        <TableHeader className="table-border-female" />
        <TableBody className="table-border-female" data={femaleData} />
      </table>
      <div className="pt-4" style={{ pageBreakBefore: 'always' }}>
        合計
      </div>
      <table className="table border-collapse">
        <TableHeader className="table-border-total" />
        <TableBody className="table-border-total" data={dataForTotal} />
      </table>
    </>
  )
}

export function ReportTestRankPage() {
  const [year, setYear] = useState(2024)
  return (
    <TochigiLayout>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <ReportTestTable key={year} year={year} />
    </TochigiLayout>
  )
}
