export const titles = {
  _id: 'ID',
  city: 'エリア',
  school_code: '学校コード',
  school_name: '学校名',
  grade: '学年',
  class: '組',
  attendance_number: '出席番号',
  gender: '性別',
  family_name: '姓',
  family_name_hiragana: '姓（かな）',
  given_name: '名',
  given_name_hiragana: '名（かな）',
  birth_year: '誕生年',
  birth_month: '誕生月',
  birth_day: '誕生日',
  height: '身長',
  weight: '体重',
  grip: '握力',
  sit_ups: '上体起こし',
  bending: '長座体前屈',
  side_jump: '反復横とび',
  shuttle_run: '20mシャトルラン',
  sprint_run: '50m走',
  standing_jump: '立ち幅とび',
  ball_throw: 'ボール投げ',
  grip_points: '握力（得点）',
  sit_ups_points: '上体起こし（得点）',
  bending_points: '長座体前屈（得点）',
  side_jump_points: '反復横とび（得点）',
  shuttle_run_points: '20mシャトルラン（得点）',
  sprint_run_points: '50m走（得点）',
  standing_jump_points: '立ち幅とび（得点）',
  ball_throw_points: 'ボール投げ（得点）',
  total_points: '体力合計点',
  rank: '総合評価',
  endurance_run_minutes: '持久走「分」',
  endurance_run_seconds: '持久走「秒」',
  q1: 'Q1',
  q2: 'Q2',
  q3: 'Q3',
  q4: 'Q4',
  q5: 'Q5',
  q6: 'Q6',
  q7: 'Q7',
  q8: 'Q8',
  q9: 'Q9',
  q10: 'Q10',
  q11: 'Q11',
  q12: 'Q12',
  ab_de: '(A+B)-(D+E)',
  participants: '受験者',
  a_percentage: 'A%',
  b_percentage: 'B%',
  c_percentage: 'C%',
  d_percentage: 'D%',
  e_percentage: 'E%',
  a: 'A',
  b: 'B',
  c: 'C',
  d: 'D',
  e: 'E',
  s_rank: 'S認定',
  s_rank_count: 'S認定人数',
  schoolCode: '学校コード',
  schoolName: '学校名',
  studentCount: '受験者',
  organizationName: '入力者所属名',
  uploaderName: '入力者名',
  schoolId: '学校ID',
  fileId: 'アップロードID',
  uploaderContactEmail: '連絡先（メール）',
  uploaderContactPhone: '連絡先（電話）',
  year: '年度',
  other: 'その他',
  totalStudentsCount: '生徒在籍数',
  participation_percentage: '実施率',
}
