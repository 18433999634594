export const currentYear = 2024

export type Questionnaire = {
  question: string
  dataKey: string
  answers: string[]
}

export const questionnaires: Questionnaire[] = [
  {
    question: '運動部やスポーツクラブへの所属状況',
    dataKey: 'q1',
    answers: ['所属している', '所属していない'],
  },
  {
    question: '運動・スポーツの実施状況',
    dataKey: 'q2',
    answers: [
      'ほとんど毎日(週3日以上)',
      'ときどき(週1〜2日程度)',
      'ときたま(月1〜3日程度)',
      'しない',
    ],
  },
  {
    question: '1日の運動・スポーツの実施時間',
    dataKey: 'q3',
    answers: [
      '30分未満',
      '30分以上1時間未満',
      '1時間以上2時間未満',
      '2時間以上',
    ],
  },
  {
    question: '小学校入学前はどのくらい外で体を動かす遊びをしていましたか',
    dataKey: 'q4',
    answers: ['週に6日以上', '週に4~5日', '週に2~3日', '週に1日以下'],
  },
  {
    question: '朝食の有無',
    dataKey: 'q5',
    answers: ['毎日食べる', '時々欠かす', 'まったく食べない'],
  },
  {
    question: '1日の睡眠時間',
    dataKey: 'q6',
    answers: ['6時間未満', '6時間以上8時間未満', '8時間以上'],
  },
  {
    question: 'ぐっすり眠ることができますか',
    dataKey: 'q7',
    answers: ['週に6日以上', '週に4~5日', '週に2~3日', '週に1日以下'],
  },
  {
    question: '1日のテレビ(テレビゲーム含む)の視聴時間',
    dataKey: 'q8',
    answers: [
      '1時間未満',
      '1時間以上2時間未満',
      '2時間以上3時間未満',
      '3時間以上',
    ],
  },
  {
    question: 'なんでも最後までやりとげたいと思う',
    dataKey: 'q9',
    answers: [
      'とてもそう思う',
      'まあそう思う',
      'あまりそう思わない',
      'まったくそう思わない',
    ],
  },
]

export const questionnaires2024: Questionnaire[] = [
  {
    question: '運動部やスポーツクラブへの所属状況',
    dataKey: 'q1',
    answers: ['所属している', '所属していない'],
  },
  {
    question: '運動・スポーツの実施状況',
    dataKey: 'q2',
    answers: [
      'ほとんど毎日(週3日以上)',
      'ときどき(週1〜2日程度)',
      'ときたま(月1〜3日程度)',
      'しない',
    ],
  },
  {
    question: '1日の運動・スポーツの実施時間',
    dataKey: 'q3',
    answers: [
      '30分未満',
      '30分以上1時間未満',
      '1時間以上2時間未満',
      '2時間以上',
    ],
  },
  {
    question: '朝食の有無',
    dataKey: 'q4',
    answers: ['毎日食べる', '時々欠かす', 'まったく食べない'],
  },
  {
    question: '1日の睡眠時間',
    dataKey: 'q5',
    answers: ['6時間未満', '6時間以上8時間未満', '8時間以上'],
  },
  {
    question: '睡眠の質（ぐっすり眠る）',
    dataKey: 'q6',
    answers: ['週に6日以上', '週に4~5日', '週に2~3日', '週に1日以下'],
  },
  {
    question: '1日のテレビ(テレビゲーム含む)の視聴時間',
    dataKey: 'q7',
    answers: [
      '1時間未満',
      '1時間以上2時間未満',
      '2時間以上3時間未満',
      '3時間以上',
    ],
  },
  {
    question: '小学校入学前の運動・スポーツの実施状況（小学校のみ）',
    dataKey: 'q8',
    answers: [
      '週に6日以上',
      '週に4～5日程度',
      '週に2～3日程度',
      '週に1日以下',
    ],
  },
  {
    question: 'なんでも最後までやり遂げたいと思う（中学校のみ）',
    dataKey: 'q9',
    answers: [
      'とてもそう思う',
      'まあそう思う',
      'あまりそう思わない',
      'まったくそう思わない',
    ],
  },
]

export const getQuestionnaires = (year: number): Questionnaire[] => {
  return year !== 2024
    ? questionnaires
    : questionnaires2024
}